import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const gethomeandtechdata = createAsyncThunk("gethomeandtechdatathunk", async () => {



    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const response = await fetch("/api/v1/homedata/gethomedata",

        {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        }


    );

    const result = await response.json();
    return result;
});

const homeandtechSlice = createSlice({
    name: 'homeandtechdata',
    initialState: {
        articles: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add any other reducers if needed

    extraReducers: (builder) => {
        builder
            .addCase(gethomeandtechdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(gethomeandtechdata.fulfilled, (state, action) => {
                state.loading = false;
                state.articles = action.payload;
            })
            .addCase(gethomeandtechdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default homeandtechSlice.reducer;
