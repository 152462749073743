import { Box, Typography } from '@mui/material';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <Box
                sx={{ textAlign: 'center', bgcolor: "#00adef", color: 'white', p: 3 }}>

                <Box sx={{
                    my: 3, "& svg": {
                        fontSize: "50px",
                        cursor: "pointer",
                        mr: "2",

                    },
                    "& svg:hover": {

                        color: "black",
                        transform: "translateX(3px)",
                        transition: "all 400ms",
                    },
                }}>
                    <XIcon />
                    <InstagramIcon />
                    <FacebookIcon />
                    <PinterestIcon />
                    <LinkedInIcon />
                    <YouTubeIcon />

                </Box>
                <Typography variant='h6'
                    sx={{
                        "@media (max-width: 600px)": {

                            fontSize: '1rem',
                        }

                    }}


                >
                    All Rights Reserved &copy; HeshMore

                </Typography>
                <Link to="/privacypolicy" style={{ color: 'white', textDecoration: 'none' }}>
                    Privacy Policy
                </Link>
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
                <Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}>
                    Contact Us
                </Link>
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
                <Link to="/termsandconditions" style={{ color: 'white', textDecoration: 'none' }}>
                    Terms and Conditions
                </Link>
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>

                <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>
                    About
                </Link>

            </Box>

        </>
    )
}

export default Footer
