import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout/Layout';

const NotFound = () => {
    return (
        <Layout>
            <h1>Page not found</h1>
            <Link className='btn btn-success' to={"/"}>Back To Home Page</Link>
        </Layout>
    )
}

export default NotFound
