import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import {} from '../redux/features/auth/authSlice'
import '../styles/ProfileStyle.css';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { Helmet } from 'react-helmet';




const Profile = () => {

    const { user } = useSelector((state) => state.auth);
    const userName = user?.name;
    const email = user?.email;
    const googlecredential = user?.googlecredential; // Access googlecredential field
    const profileImageUrl = user?.profileImageUrl;
    // const profileImageUrl = ''


    const navigate = useNavigate(); // Get navigation function


    // useEffect(() => {
    //     if (!user) {
    //         // If no user is found, navigate to login page
    //         navigate('/login');
    //     }
    // }, [user]);

    const handleUpdateProfile = () => {
        if (!googlecredential) { // Check if googlecredential is absent
            navigate('/updateprofile'); // Navigate on button click
        }
    };





    return (

        <Layout>

            <Helmet>
                <title>Profile</title>
                <meta
                    name="description"
                    content="Profile page for app.heshmore.com"
                />
                <meta name="keywords" content="heshmore app, profile page" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/profile" />
            </Helmet>




            <Box className="profile-container" sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="h2">
                    Profile
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <Typography variant="h4" component="h2">
                        Profile
                    </Typography> */}

                    <Typography variant="body1" component="span" sx={{ margin: '5px 0', fontWeight: 'bold' }}>
                        Profile Picture:
                    </Typography>
                    {/* <div className="profile-image-container">
                        {profileImageUrl ? (
                            <img
                                src={profileImageUrl}
                                alt="Profile"
                                className="circular-image"
                            /> */}
                    {/* <div className="profile-image-container">
                        {profileImageUrl ? (
                            <div className="icon-container">
                                <AccountCircleOutlinedIcon
                                    className="icon-base"
                                />
                                <img
                                    src={profileImageUrl}
                                    alt="Profile"
                                    className="profile-image"
                                />
                            </div>
                        ) : (
                            // <p>No profile image available</p>
                            <AccountCircleOutlinedIcon
                                style={{ fontSize: 25 }} // Adjust size if needed
                                className="circular-image"
                            />
                        )}
                    </div> */}

                    <div className="profile-container">
                        <Avatar
                            src={profileImageUrl}
                            alt="Profile"
                            style={{ width: 50, height: 50 }}
                        >
                            {!profileImageUrl && <PersonIcon />}
                        </Avatar>
                    </div>



                    <Typography variant="body1" component="span" sx={{ margin: '5px 0', fontWeight: 'bold' }}>
                        Name: {userName}
                    </Typography>
                    <Typography variant="body1" component="span" sx={{ margin: '5px 0', fontWeight: 'bold' }}>
                        Email: {email}
                    </Typography>
                </Box>
                <br></br>
                <button type="button" className="btn btn-primary" sx={{ margin: '10px 10px' }}
                    onClick={handleUpdateProfile}
                    disabled={googlecredential}>
                    Update Profile
                </button>
                {googlecredential && ( // Conditionally render note
                    <Typography variant="caption" sx={{ color: 'gray', marginTop: '5px' }}>
                        *Profile update is disabled since logged in using Google.
                    </Typography>
                )}
            </Box>
            <br></br>

        </Layout >


    );
}

export default Profile
