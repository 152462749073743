//import e from 'cors';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Inputform from '../components/shared/inputform';
import e from 'cors';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Spinner from '../components/shared/spinner.js';
import { toast } from 'react-toastify';
// import GoogleSignInButton from '../components/GoogleSignIn.js';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import ForgotPasswordForm from "../components/shared/ForgotPasswordForm.js";
import { setUser, setCreatorStatus } from '../redux/features/auth/authSlice.js';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';


const Login = () => {

    // const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    // const [confirmpassword, setconfirmpassword] = useState("");
    const [showForgotForm, setShowForgotForm] = useState(false);

    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);



    //hooks

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //redux state

    const { loading } = useSelector((state) => state.alerts);



    //form function handle submit

    const handlesubmit = async (e) => {
        e.preventDefault();

        // const captchatoken = grecaptcha.getResponse();
        // Ensure the reCAPTCHA token is retrieved correctly
        const captchatoken = window.grecaptcha.getResponse();


        try {
            if (!email || !password) {
                toast.error('Please provide email and password');
                return;
            }

            if (!captchatoken) {
                toast.error('Please complete the CAPTCHA');
                return;
            }

            dispatch(showLoading());

            const { data } = await axios.post('api/v1/auth/login', { email, password, captchatoken });

            // dispatch(hideLoading());

            if (data.success) {

                //*************************************************************************** */

                // code added to check if user is creator

                // Fetch creator status
                const creatorStatus = await axios.get(`/api/v1/creatoraccount/getcreatoraccount?userId=${data.user._id}`);
                dispatch(setCreatorStatus(creatorStatus.data.isCreator));
                localStorage.setItem('token', data.token);
                localStorage.setItem('isCreator', creatorStatus.data.isCreator);
                navigate('/dashboard');
                dispatch(hideLoading());


                toast.success('Login Success');


            } else {
                // Handle all other responses (including unverified email)
                toast.error(data.message || 'Invalid Credentials'); // Use data.message if available, otherwise fallback to "Invalid Credentials"
            }
        } catch (error) {
            dispatch(hideLoading());
            console.error(error);
            toast.error('An error occurred. Please try again later.');
        }

    }

    //********************************************************************************************* */   


    const handleForgotClick = () => {
        setShowForgotForm(true);
    };

    //********************************************************************************************* */   

    const handleSuccess = (credentialResponse) => {

        dispatch(showLoading());

        const decoded = jwtDecode(credentialResponse.credential);
        const user = {
            email: decoded.email,
            name: decoded.name,
            credentialId: decoded.sub,
        };

        // Send user data to backend
        fetch('/api/v1/google-signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        })
            .then(response => response.json())
            .then(async data => {
                dispatch(hideLoading());

                // console.log("Data Returned from Google is", data);
                // localStorage.setItem('token', data.token);
                // toast.success('Login Success');
                // navigate('/dashboard');

                if (data.token) {
                    localStorage.setItem('token', data.token);

                    // code added to check if user is creator

                    // Fetch creator status
                    const creatorStatus = await axios.get(`/api/v1/creatoraccount/getcreatoraccount?userId=${data.user._id}`);
                    dispatch(setCreatorStatus(creatorStatus.data.isCreator));
                    // console.log("google signin data.user._id is ", data.user._id);
                    navigate('/dashboard');
                    toast.success('Login Success');
                } else {
                    toast.error('Unable to login with google id');
                    console.log(data.message);


                }


            })
            .catch(error => {

                dispatch(hideLoading());
                console.error('Error:', error)
            });
    }

    //********************************************************************************************************* */
    const handleCaptchaChange = (value) => {
        // console.log("Captcha value:", value);
        setIsCaptchaVerified(true);
    };


    //********************************************************************************************************** */    

    return (<>

        <Helmet>
            <title>Login</title>
            <meta
                name="description"
                content="Login for app.heshmore.com"
            />
            <meta name="keywords" content="login" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://app.heshmore.com/login" />
        </Helmet>


        {
            loading ? (
                <Spinner />
            ) : (



                <div className='form-container'>


                    <form onSubmit={handlesubmit}>
                        <div className='imagelogo'>
                            <img src='/assets/images/HeshMoreLogo.png' alt='HeshMoreApp Logo' height={50} width={50}></img>
                        </div>
                        <h5 className="text-center text-primary">HeshMore App</h5>



                        <Inputform htmlFor="email"

                            labeltext={'Email:'}
                            type={'email'}
                            value={email}
                            handlechange={(e) => setemail(e.target.value)}
                            name="email"

                        />

                        <Inputform htmlFor="password"

                            labeltext={'Password:'}
                            type={'password'}
                            value={password}
                            handlechange={(e) => setpassword(e.target.value)}
                            name="password"

                        />
                        <button type="submit" className="btn btn-primary" disabled={!isCaptchaVerified}>Login</button>


                        <div className='d-flex justify-content-between'>
                            <p style={{ marginRight: '10px', marginTop: '7px' }}>Dont have an account?</p>
                            <Link to="/register" style={{ marginTop: '7px' }}>Register</Link>
                        </div>


                        <GoogleLogin
                            onSuccess={handleSuccess}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />



                    </form>


                    <br></br>
                    <Link onClick={handleForgotClick} to="#">Forgot Password?</Link>
                    {showForgotForm && <ForgotPasswordForm />}

                    <br></br>

                    <ReCAPTCHA
                        sitekey="6LdVzjMqAAAAAB-jciNDEdVabolKjg0afXMr6zle"
                        onChange={handleCaptchaChange}
                    />

                </div>





            )};

    </>
    )
}

export default Login

