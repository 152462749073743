import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Import necessary components for displaying results (e.g., List, Typography)
import Spinner from '../components/shared/spinner.js';
// import ImageFiller from 'react-image-filler';
import { formatTimeAgo } from '../utils/timeUtils.js';
import Layout from '../components/Layout/Layout';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';


const SearchPage = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Initialize searchTerm state to empty string

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setIsLoading] = useState(false); // Track loading state


    useEffect(() => {
        const searchTermFromUrl = new URLSearchParams(location.search).get("q");
        setSearchTerm(searchTermFromUrl); // Set searchTerm from URL query params

        const fetchData = async () => {
            setIsLoading(true); // Set loading state to true
            try {
                if (searchTermFromUrl) {
                    const response = await fetch(`https://us-east-1.aws.data.mongodb-api.com/app/application-1-gvjvv/endpoint/SearchTerm?searchTerm=${searchTermFromUrl}&secret=aX46TcMqt8CRHDxPfZLEbu4nySyUE`);
                    const results = await response.json();


                    // Filter out duplicates based on title
                    const filteredData = results.filter((article, index) => {
                        return results.findIndex((a) => a.title === article.title) === index;
                    });

                    setSearchResults(filteredData);



                } else {
                    // Handle case where no search term is present (optional)
                    navigate("/"); // Example: redirect to homepage
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors gracefully (optional: display an error message)
            } finally {
                setIsLoading(false); // Set loading state to false after fetch (or error)
            }
        };
        fetchData();
    }, [location]);





    return (
        <Layout>
            {searchTerm && ( // Conditionally render message based on searchTerm existence
                <h1>Search Results for: {searchTerm}</h1>
            )}
            {loading ? (
                <Spinner /> // Display a loading indicator while fetching data
            ) : (
                searchResults.length > 0 && ( // Display results only if there are some


                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", padding: "20px" }}> {/* Add padding for left and right */}
                        {searchResults.map((result) => (
                            <Card key={result.id} sx={{ maxWidth: "100%", display: "flex", mb: 2 }}>
                                <CardActionArea href={`${result.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=newsapiandnewsdata`} target="_blank">
                                    {/* <CardMedia
                                        component="img"
                                        image={result.jetpackFeaturedMediaUrl}
                                        alt={result.title}
                                        onError={(e) => {
                                            // e.target.src = "/assets/images/Imagenotavailable.png"; // Use fallback image on error
                                            <img src="/assets/images/Imagenotavailable.png" alt="Image not available" width={400} height={200} />

                                        }}
                                        sx={{ width: 200, height: 100, objectFit: "cover" }} // Set fixed size and object-fit
                                    /> */}

                                    {result.jetpackFeaturedMediaUrl ? (
                                        <CardMedia
                                            component="img"
                                            image={result.jetpackFeaturedMediaUrl}
                                            alt={result.title}
                                            sx={{ width: 200, height: 100, objectFit: "cover", padding: 1 }}
                                        />
                                    ) : (
                                        <img src="/assets/images/Imagenotavailable.png" alt="Image not available" width={200} height={100} />
                                    )}
                                    <CardContent sx={{ width: "calc(100% - 20px)", pl: 2, mt: 0 }}> {/* Adjust width and add padding */}
                                        {/* <Typography variant="h6" gutterBottom component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '4.5em', lineHeight: '1.5em' }}>
                                        {result.title}
                                    </Typography> */}
                                        <Typography variant="h6" gutterBottom component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '4.5em', lineHeight: '1.5em' }}>
                                            {result.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {result.provider} - {formatTimeAgo(result.dateGmt)} ago
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>

                            </Card>
                        ))}
                    </div>
                )
            )}
            {searchResults.length === 0 && !loading && ( // Display message if no results found
                <p>No results found.</p>
            )}
        </Layout>
    );
};

export default SearchPage;

