import React, { useEffect, useState, useMemo, useRef } from 'react';
import Layout from '../components/Layout/Layout.js';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, IconButton } from '@mui/material';

import { useSelector } from 'react-redux';
// import { gethomeandtechdata } from '../redux/features/homeandtechSlice.js';
// import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Spinner from '../components/shared/spinner.js';
import { formatTimeAgo } from '../utils/timeUtils.js';
// import ImageFiller from 'react-image-filler';
import { FavoriteSharp, Share, Email, Facebook, WhatsApp, X, LinkedIn } from '@mui/icons-material'; // Import icons
import axios from 'axios';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
// import NumberFormat from 'number-format.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CopyLinkButton from '../components/Layout/CopyLinkButton.js';
import { useQueryClient, useQuery } from 'react-query';
import ImageFiller from 'react-image-filler';
import parse from 'html-react-parser';
import he from 'he';
import { useTheme } from '../components/theme-context.js';

import YouTube from 'react-youtube'; // Install using npm install react-youtube
import { Helmet } from 'react-helmet';



const TrendingVideos = () => {


    const [playerloading, setplayerLoading] = useState(false); // State to track loading


    const playerRefs = useRef({}); // Object to store individual player references


    const [playingVideoId, setPlayingVideoId] = useState(null); // State to track which video is playing






    const { isDarkMode } = useTheme();

    const queryClient = useQueryClient(); // Get the queryClient instance


    const { user } = useSelector((state) => state.auth);
    const userId = user?._id; // Assuming userId is stored in '_id' property
    const [loading, setIsLoading] = useState(false); // Track loading state
    const [favoriteArticles, setfavoriteArticles] = useState([]);
    const [favoriteStatus, setFavoriteStatus] = useState([]); // Stores article IDs for liked articles
    const [isModalOpen, setIsModalOpen] = useState(false); // stores share icon state
    const [trendingArticles, settrendingArticles] = useState([]);

    //***************************************************************************************************** */
    // Function to filter and sort articles
    const filterAndSortArticles = (articles) => {

        // console.log('Articles before filtering:', articles);

        // console.log('Articles before filtering:', articles);
        const filteredArticles = articles.filter((article, index) => {
            return articles.findIndex((a) => a.title === article.title) === index;
        });
        // console.log('Articles after filtering:', filteredArticles);

        // Sort filteredArticles by date (assuming 'dateGmt' is the date property)
        const sortedArticles = filteredArticles.sort((a, b) => {
            return new Date(b.dateGmt) - new Date(a.dateGmt);
        });
        // console.log('Articles after sorting:', sortedArticles);

        return sortedArticles;
    };

    //******************************************************************************************************** */   

    //******************************************************************************************************** */   
    //fetch favorites from api
    const fetchFavorites = async (userId) => {

        setIsLoading(true);
        try {
            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            if (userId) {
                const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=video`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                        }
                    });
                const favoriteIds = response.data.data;

                // setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

                return response.data.data.map(article => article.articleId);

            }
        } catch (error) {
            // dispatch(hideLoading()); // Dispatch hideLoading action

            setIsLoading(false);

            console.error('Error fetching favorites:', error);
        } finally {
            // dispatch(hideLoading()); // Dispatch hideLoading action
            setIsLoading(false);
        }

    }


    //********************************************************************************************************


    //******************************************************************************************************** */   
    //fetch favorites from api
    const fetchTrendingfromApi = async (userId) => {
        setIsLoading(true);
        try {
            // if (userId) {
            const result = await axios.get(`/api/v1/clickcount/gettrendingarticles?articleType=video`);
            // console.log("Trending DATA articles api response is ", result);
            return result.data; // Adjusted to return the entire data object
            //}
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching favorites:', error);
        } finally {
            setIsLoading(false);
        }
    };



    //********************************************************************************************************



    // useEffect(() => {
    //     const fetchTrendingarticles = async () => {
    //         setIsLoading(true); // Set loading state to true

    //         try {
    //             if (userId) {
    //                 const response = await axios.get(`/api/v1/clickcount/gettrendingarticles?articleType=article`);

    //                 // console.log('data received for trending articles is ', response.data)
    //                 // const favoriteIds = response.data.data;
    //                 // console.log(favoriteIds);
    //                 // console.log("favorite article ids are ", favoriteIds);
    //                 // // setFavoriteStatus(favoriteIds);
    //                 // setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs
    //                 settrendingArticles(response.data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching trendingarticles in gettrendingarticles endpoint:', error);
    //         } finally {
    //             setIsLoading(false); // Set loading state to false after fetch (or error)
    //         }
    //     };

    //     fetchTrendingarticles();
    // }, [userId]);

    //******************************************************************************************************** */
    //fetch trending data with react query

    const { data: trendingVideosData = [], error: trendingError, isLoading: isTrendingLoading } =

        useQuery('trendingVideosData', async () => {

            // dispatch(showLoading());

            setIsLoading(true);


            try {

                const result = await fetchTrendingfromApi(userId);

                // console.log("trending data api response is ", result)
                return result;



            } catch (error) {
                // Handle error
                // dispatch(hideLoading());
                setIsLoading(false);
                toast.error("error occurred while fetching data from api");
                console.log("error occurred while loading data on Trending page", error);
            } finally {
                // dispatch(hideLoading()); // Dispatch hideLoading action
                setIsLoading(false);
            }
        }, {
            // enabled: !!userId, // Only run the query if userId is not null

            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes


        });


    //************************************************************************************************************** */
    //************************************************************************************************************** */

    // load favorites with react-query

    const { data: favoriteIds = [], error: favoritesError, isLoading: isFavoritesLoading }
        = useQuery(['favorites', userId], async () => {

            // dispatch(showLoading());
            setIsLoading(true);
            try {
                const result = await fetchFavorites(userId);

                // console.log('Favorite IDs from api response:', result); // Add this line

                setFavoriteStatus(result);
                return result;


            } finally {
                // dispatch(hideLoading());
                setIsLoading(false);
            }
        }, {
            enabled: !!userId, // Only run the query if userId is not null
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes
        });

    //************************************************************************************************************** */

    //**************************************************************************************************

    const combinedData = useMemo(() => {
        // if (!favoriteIds.length || !trendingVideosData.length) return [];

        if (!trendingVideosData.length) return [];



        // Filter out articles with missing _id or other required fields
        const validArticles = trendingVideosData.filter(ele => ele._id && ele.title);



        return validArticles.map((ele) => ({
            ...ele,
            isFavorite: favoriteIds.includes(ele._id),
        }));
    }, [favoriteIds, trendingVideosData]);



    //*********************************************************************************************** */ 

    // Fetch user's favorite articles for favoriteicon during initial load
    // useEffect(() => {
    //     const fetchFavorites = async () => {
    //         try {
    //             if (userId) {
    //                 const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=article`);
    //                 const favoriteIds = response.data.data;
    //                 // console.log(favoriteIds);
    //                 console.log("favorite article ids are ", favoriteIds);
    //                 // setFavoriteStatus(favoriteIds);
    //                 setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

    //             }
    //         } catch (error) {
    //             console.error('Error fetching favorites:', error);
    //         }
    //     };

    //     fetchFavorites();
    // }, [userId]);


    //***************************************************************************************************** */

    // Handle favorite click logic with client-side state update and server-side interaction
    const handleFavoriteClick = async (articleId, collectionName) => {
        // const articleId = article._id;
        const isAlreadyFavorited = favoriteStatus.includes(articleId);

        // // Optimistic UI update: Toggle favorite color based on current state
        // setFavoriteStatus((prevStatus) => (isAlreadyFavorited ? prevStatus.filter((id) => id !== articleId) : [...prevStatus, articleId]));
        // Optimistic UI update: Toggle favorite color based on current state

        setFavoriteStatus((prevStatus) => (isAlreadyFavorited ? prevStatus.filter((id) => id !== articleId) : [...prevStatus, articleId]));

        // Update the favoriteIds in React Query cache
        queryClient.setQueryData(['favorites', userId], (oldData) =>
            isAlreadyFavorited ? oldData.filter((id) => id !== articleId) : [...oldData, articleId]
        );

        if (isAlreadyFavorited) {
            try {
                const response = await axios.post(`/api/v1/favorite/deleteFavorite/${userId}/${articleId}`);
                if (response.success) {
                    console.log('Favorite deleted successfully!');
                    setFavoriteStatus((prevStatus) => prevStatus.filter((id) => id !== articleId));
                    // setFavoriteStatus(response.articleId);
                } else {
                    console.error('Error deleting favorite:', response.message);
                }
            } catch (error) {
                console.error('Error deleting favorite:', error);
            }
        } else {
            try {
                const favoriteData = {
                    userId,
                    articleId,
                    articleType: "article", // Or any other relevant type (modify if needed)
                    collectionName,
                };
                const response = await axios.post('/api/v1/favorite/createFavorite', favoriteData);
                if (response.success) {
                    console.log('Favorite created successfully!');
                    setFavoriteStatus((prevStatus) => [...prevStatus, articleId]);
                    // setFavoriteStatus(response.articleId)
                } else {
                    console.error('Error creating favorite:', response.message);
                }
            } catch (error) {
                console.error('Error creating favorite:', error);
            }
        }
    };


    //************************************************************************************************** */   

    const handleClickCount = async (articleId, collectionName) => {
        // setIsClicked(true); // Update local state for UI feedback (optional)
        try {

            const clickData = {

                articleId,
                collectionName,
                articleType: 'video',
            };
            const response = await axios.post('/api/v1/clickcount/incrementclickcount?articleType=video', clickData);

            if (!response.ok) {
                throw new Error('Error incrementing click count');
            }

            console.log('Click count incremented successfully');
        } catch (error) {
            console.error('Error incrementing click count:', error);
        }
    };





    //******************************************************************************************************************* */

    // Assuming you have an array named `uniqueArticles` containing article data
    // const formattedArticles = trendingArticles.map((article) => {
    //     const clickCount = article.clickcount; // Extract click count property
    //     const formattedClickCount = NumberFormat.format(clickCount, {
    //         thousands: true, // Enable thousands separator
    //         // You can add additional formatting options here:
    //         // decimal: 2, // Specify number of decimal places (optional)
    //     });

    // });




    // Add logging to check the state of homeandTechData and favoriteIds
    // console.log('TRENDING DATA is:', trendingVideosData);
    // console.log('favoriteIds:', favoriteIds);

    // Filter and sort articles
    // const uniqueArticles = techData && techData.payload ? filterAndSortArticles(techData.payload) : [];
    // const uniqueArticles = trendingVideosData ? filterAndSortArticles(trendingVideosData) : [];

    const uniqueArticles = combinedData.length > 0 ? filterAndSortArticles(combinedData) : [];

    // console.log("Unique Articles are ", uniqueArticles);




    //************************************************************************************************************* */

    if (isTrendingLoading || isFavoritesLoading) {
        return <div className="loading-text">Loading...</div>;

        // return (
        //     <div className="loading-text">
        //         <ClipLoader color="#00adef" loading={true} size={25} />
        //         Loading...
        //     </div>
        // );
    }
    if (trendingError) {
        return <div className="loading-text">Error loading  trending data</div>;
    }
    if (favoritesError) {
        return <div className="loading-text">Error loading Trending</div>;
    }

    //*********************************************************************************************************** */

    //************************************************************************************************************* */

    if (isTrendingLoading || isFavoritesLoading) {
        return <div className="loading-text">Loading...</div>;

        // return (
        //     <div className="loading-text">
        //         <ClipLoader color="#00adef" loading={true} size={25} />
        //         Loading...
        //     </div>
        // );
    }
    if (trendingError) {
        return <div className="loading-text">Error loading  youtube data</div>;
    }
    if (favoritesError) {
        return <div className="loading-text">Error loading favorites</div>;
    }


    //************************************************************************************************* */
    const onReady = (event, videoId) => {
        playerRefs.current[videoId] = event.target; // Store the player reference
        event.target.playVideo(); // Play the video when the player is ready
        setplayerLoading(false);

    };


    //*************************************************************************************************
    // const playVideo = (videoId) => {
    //     if (playerRefs.current[videoId]) {
    //         playerRefs.current[videoId].playVideo(); // Play the video using the stored reference
    //         setPlayingVideoId(videoId); // Set the currently playing video ID
    //     } else {
    //         console.warn(`Player reference not found for video ID: ${videoId}`);
    //     }
    // };

    // const playVideo = (videoId) => {
    //     setPlayingVideoId(videoId); // Set the currently playing video ID
    //     setplayerLoading(true);
    // };

    const playVideo = (videoId, articleId, collectionName) => {
        setPlayingVideoId(videoId); // Set the currently playing video ID
        setplayerLoading(true);
        // Call handleClickCount to increment the click count for this video
        handleClickCount(articleId, collectionName);
    };




    //****************************************************************************************************** */









    return (
        <Layout>

            <Helmet>
                <title>Trending Videos</title>
                <meta
                    name="description"
                    content="Trending video news in technology, gadgets, AI, and innovations. Get breaking tech news and articles from top sources."
                />
                <meta name="keywords" content="videos, trending technology news videos, gadgets, AI, innovations, breaking news, science news, gaming, gaming news" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/trendingvideos" />
            </Helmet>




            {
                loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className={isDarkMode ? 'dark-mode' : ''}>


                            <Typography sx={{ ml: 3 }}>

                                <h5>Trending Videos</h5>

                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>




                                {/* {uniqueArticles.map((ele, index) => ( */}
                                {uniqueArticles.length > 0 ? (
                                    uniqueArticles.map((ele) => (

                                        <>

                                            {/* <Card sx={{ maxWidth: "390px", display: "flex", m: 2 }}> */}
                                            <Card key={ele._id} sx={{ maxWidth: 400, display: 'grid', gridTemplateRows: 'auto 1fr', m: 2 }}>


                                                {/* <CardActionArea href={`https://www.youtube.com/watch?v=${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=newsapiandnewsdata`} target="_blank">
                                                    {ele.jetpackFeaturedMediaUrl ? (
                                                        <CardMedia
                                                            component="iframe"
                                                            src={`https://www.youtube.com/embed/${ele.link}`}
                                                            title={ele.title}
                                                            height="250"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                        />
                                                    ) : (
                                                        <ImageFiller width={400} height={200} text="Image not available" />
                                                    )}

                                                    <CardContent className="CardContent">
                                                        <div>

                                                            <Typography variant="h6"

                                                                gutterBottom component="div"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxHeight: '4.5em', lineHeight: '1.5em'
                                                                }}>
                                                               

                                                                {ele.title}


                                                            </Typography>
                                                            <Typography variant='body' >
                                                                {ele.provider}
                                                            </Typography>

                                                            <Typography variant='body2'>
                                                                {formatTimeAgo(ele.dateGmt)} ago
                                                            </Typography>
                                                        </div>

                                                    </CardContent>



                                                </CardActionArea> */}


                                                <CardActionArea onClick={() => playVideo(ele.link, ele._id, ele.collectionName)}>

                                                    {loading && <div className="loading-spinner">Loading...</div>}

                                                    {playingVideoId === ele.link ? (
                                                        <>

                                                            <YouTube
                                                                videoId={ele.link}
                                                                onReady={(event) => onReady(event, ele.link)}
                                                                opts={{
                                                                    // height: '250',
                                                                    // width: '400',
                                                                    width: '100%',  // Responsive width
                                                                    height: '225px', // Set a fixed height that matches the thumbnail height
                                                                    playerVars: {
                                                                        autoplay: 0,
                                                                    },
                                                                }}
                                                                style={{
                                                                    aspectRatio: '16/9',  // Maintain a 16:9 aspect ratio for the video
                                                                    // width: '100%',
                                                                    // height: '250px',  // Ensure the video height matches thumbnail
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="thumbnail" style={{ position: 'relative', width: '100%', height: 'auto', aspectRatio: '16/9' }}>
                                                            <img src={ele.jetpackFeaturedMediaUrl}
                                                                alt={ele.title}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover', // This will ensure the image fits within the container without distortion
                                                                }}

                                                            />
                                                            <PlayButtonOverlay />
                                                        </div>
                                                    )}
                                                </CardActionArea>
                                                <CardContent className="CardContent">
                                                    <div>

                                                        <Typography variant="h6"

                                                            gutterBottom component="div"
                                                            sx={{
                                                                // // overflow: 'hidden',
                                                                // // textOverflow: 'ellipsis',
                                                                // wordWrap: 'break-word',
                                                                // // maxHeight: '4.5em', 
                                                                // lineHeight: '1.5em'
                                                                wordWrap: 'break-word',
                                                                wordBreak: 'break-word',  // Ensures text breaks to the next line
                                                                whiteSpace: 'normal',     // Allows text wrapping
                                                                lineHeight: '1.5em',
                                                            }}>
                                                            {ele.title ? parse(he.decode(ele.title)) : 'No title available'}

                                                        </Typography>
                                                        <Typography variant='body' >
                                                            {ele.provider}
                                                        </Typography>

                                                        <Typography variant='body2'>
                                                            {formatTimeAgo(ele.dateGmt)} ago
                                                        </Typography>
                                                    </div>

                                                </CardContent>


                                                <div style={{ backgroundColor: 'var(--background-color)', color: 'var(--card-text-color)' }}>
                                                    <IconButton
                                                        aria-label="like"
                                                        onClick={() => handleFavoriteClick(ele._id, ele.collectionName)}
                                                        // sx={{ bgcolor: favoriteStatus.includes(ele._id) ? 'lightcoral' : 'transparent' }}>
                                                        sx={{ ml: 1 }}>

                                                        {/* {favoriteStatus.includes(ele._id) ? <Favorite sx={{ color: 'red' }} /> : <Favorite />} */}

                                                        {/* {favoriteStatus.includes(ele._id) ? (
                                                        <FavoriteSharp sx={{ color: 'red' }} /> // Set the color to red for filled heart
                                                    ) : (
                                                        <FavoriteSharp /> // Default (outline) heart
                                                    )} */}

                                                        {favoriteStatus.includes(ele._id) || ele.isFavorite ? (
                                                            <FavoriteSharp sx={{ color: 'red' }} />
                                                        ) : (
                                                            <FavoriteSharp />
                                                        )}
                                                    </IconButton>


                                                    {/* Add Share Button and Modal Logic Here */}
                                                    {/* <IconButton aria-label="share" onClick={() => setIsModalOpen(ele._id)}> */}
                                                    <IconButton aria-label="share" onClick={() => { setIsModalOpen(ele._id === isModalOpen ? false : ele._id); }}> {/* Toggle state on click */}

                                                        <Share />  {/* Optional: Icon for the share button */}
                                                    </IconButton>

                                                    {/* Modal Container (initially hidden) */}
                                                    {isModalOpen === ele._id && (
                                                        <div className="share-modal">
                                                            <h6 style={{ marginLeft: '5px', marginTop: '5px' }}>Share this video</h6>
                                                            <EmailShareButton url={`https://www.youtube.com/watch?v=${ele.link}`} subject="Check out this video!" body="This is the video you should watch.">
                                                                <Email size={32} round />
                                                            </EmailShareButton>
                                                            <FacebookShareButton url={`https://www.youtube.com/watch?v=${ele.link}`} quote="Check out this video!">
                                                                <Facebook size={32} round />
                                                            </FacebookShareButton>
                                                            <WhatsappShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <WhatsApp size={32} round />
                                                            </WhatsappShareButton>
                                                            <TwitterShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <X size={32} round />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <LinkedIn size={32} round />
                                                            </LinkedinShareButton>
                                                            {/* <button onClick={() => navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`)}>Copy Link</button> */}
                                                            <CopyLinkButton link={`https://www.youtube.com/watch?v=${ele.link}`} />


                                                            {/* Add buttons for other platforms like Twitter, etc. */}
                                                            {/* <button onClick={() => setIsModalOpen(false)}>Close</button> */}
                                                        </div>
                                                    )}

                                                </div>

                                                <div style={{ backgroundColor: 'var(--background-color)', color: 'var(--card-text-color)' }}>


                                                    <Typography variant="body2" color="text.secondary" ml={2}>
                                                        {ele.clickcount} views
                                                    </Typography>
                                                </div>

                                            </Card >

                                        </>
                                    ))

                                    //}

                                ) : (
                                    <div>No videos found or error loading videos, please login again or check after some time</div>
                                )}

                            </Box>
                        </div>

                    </>
                )
            }
        </Layout >

    );
}

const PlayButtonOverlay = () => {
    return (
        <div className="play-button-overlay">
            <img src="/assets/images/youtubeplaybutton.png" alt="Play Button" />

        </div>
    );
};

export default TrendingVideos;