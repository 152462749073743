//import e from 'cors';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Inputform from '../components/shared/inputform';
// import e from 'cors';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import axios from 'axios';
import Spinner from '../components/shared/spinner.js';
import { toast } from 'react-toastify';
import Layout from '../components/Layout/Layout.js';
import AWS from 'aws-sdk';
import ImageCropper from '../components/Layout/ImageCropper.js';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';




const Register = () => {


    // AWS.config.update({
    //     accessKeyId: 'AKIAWMVMKBV72BCNYION',
    //     secretAccessKey: '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',
    //     region: 'ap-south-1'
    // });

    // const s3 = new AWS.S3();
    const [file, setFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [awss3imageurl, setAwsS3imageUrl] = useState(null);



    // const uploadToS3 = async (file) => {

    //     if (!file || !file.name) {
    //         console.error('Invalid file object:', file);
    //         toast.error('Unable to upload file. Invalid file object.');
    //         return null;
    //     }

    //     const uniqueFileName = `${uuidv4()}-${file.name}`;




    //     const params = {
    //         Bucket: 'heshmoreappimages',
    //         Key: uniqueFileName,
    //         Body: file,
    //         ContentType: file.type,
    //         // ACL: 'public-read'
    //     };


    //     try {
    //         const data = await s3.upload(params).promise();
    //         console.log('File uploaded successfully:', data.Location);
    //         return data.Location;
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //         toast.error("Unable to upload file due to database error, please try again or contact support");
    //         return null;
    //     }
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        const maxSize = 5 * 1024 * 1024; // 5 MB size limit

        if (file.size > maxSize) {
            alert('File size exceeds the 5 MB limit. Please choose a smaller file.');
            return;
        }




        setFile(URL.createObjectURL(file));
        setIsCropping(true);

    };

    const handleCropComplete = async (croppedImage) => {
        setCroppedImage(URL.createObjectURL(croppedImage));
        setIsCropping(false);
        // const imageUrl = await uploadToS3(croppedImage);
        // console.log('Image URL:', imageUrl);


        const formData = new FormData();
        formData.append('file', croppedImage);
        // console.log("form data is ", formData);

        // Log FormData contents to check if the file has been appended correctly
        // for (const [key, value] of formData.entries()) {
        //     console.log(`${key}:`, value);
        // }

        try {
            const response = await fetch('/api/v1/user/uploadProfileImage/', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            const data = await response.json();
            console.log('Image URL:', data.fileUrl);
            setAwsS3imageUrl(data.fileUrl);
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Unable to upload file. Please try again.');
        }
    };







    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");

    //redux state

    const { loading } = useSelector((state) => state.alerts);


    //hooks

    const dispatch = useDispatch();
    const navigate = useNavigate();


    //form function handle submit

    const handlesubmit = async (e) => {

        e.preventDefault();

        try {

            if (!name || !email || !password || !confirmpassword) {

                toast.error('Please provide all fields');
                return;


            }

            if (password !== confirmpassword) {
                toast.error('Passwords do not match');
                return; // Exit early if passwords don't match
            }


            dispatch(showLoading());

            const { data } = await axios.post('api/v1/auth/register', {
                name,
                email,
                password,
                profileImageUrl: awss3imageurl,  // Pass profile image URL if available

            });

            if (data.success) {
                dispatch(hideLoading());


                toast.success('User Registered, please verify your email to login');

                navigate('/login')


            }




            // console.log(name, email);
        } catch (error) {
            dispatch(hideLoading());
            //  alert('something went wrong please try again')
            toast.error("Something went wrong please try again");
            console.log(error);
        }

    }


    return (<>

        <Helmet>
            <title>Register</title>
            <meta
                name="description"
                content="Register page for app.heshmore.com"
            />
            <meta name="keywords" content="heshmore app register, create user account" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://app.heshmore.com/register" />
        </Helmet>

        {
            loading ? (
                <Spinner />
            ) : (

                <div className='form-container'>

                    <form onSubmit={handlesubmit}>
                        <div className='imagelogo'>
                            <img src='/assets/images/HeshMoreLogo.png'
                                alt='HeshMoreApp Logo'
                                height={50}
                                width={50}
                                margin-top={20}>
                            </img>
                        </div>
                        <h5 className="text-center text-primary">HeshMore App</h5>
                        {/* <div>
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                            {isCropping ? (
                                <ImageCropper imageSrc={file} onCropComplete={handleCropComplete} />
                            ) : (
                                croppedImage && <img src={croppedImage} alt="Cropped" />
                            )}
                        </div> */}

                        {/* <div>
                            {croppedImage && !isCropping && <img src={croppedImage} alt="Cropped" />}
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                            {isCropping && (
                                <ImageCropper imageSrc={file} onCropComplete={handleCropComplete} />
                            )}
                        </div> */}

                        <p>Upload Profile Picture:</p>
                        <div className="upload-container">


                            {croppedImage && !isCropping && <img src={croppedImage} alt="Cropped" className="circular-image" />}
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                            {isCropping && (
                                <ImageCropper imageSrc={file} onCropComplete={handleCropComplete} />
                            )}
                        </div>


                        <Inputform
                            htmlFor="name"
                            labeltext={'Name:'}
                            type={'text'}
                            value={name}
                            handlechange={(e) => setname(e.target.value)}
                            name="name"

                        />


                        <Inputform
                            htmlFor="email"
                            labeltext={'Email:'}
                            type={'email'}
                            value={email}
                            handlechange={(e) => setemail(e.target.value)}
                            name="email"

                        />

                        <Inputform
                            htmlFor="password"
                            labeltext={'Password:'}
                            type={'password'}
                            value={password}
                            handlechange={(e) => setpassword(e.target.value)}
                            name="password"

                        />

                        <Inputform
                            htmlFor="confirmpassword"
                            labeltext={'Confirm Password:'}
                            type={'password'}
                            value={confirmpassword}
                            handlechange={(e) => setconfirmpassword(e.target.value)}
                            name="confirmpassword"

                        />

                        <button type="submit" className="btn btn-primary">Register</button>


                        <div className="d-flex justify-content-between">

                            <p>Already Registered? <Link to="/login">Login</Link></p>



                        </div>

                    </form>


                </div>


            )};
    </>)
}

export default Register
