import React, { useState } from 'react';
import axios from 'axios'; // Assuming you're using Axios for API calls
import * as EmailValidator from 'email-validator';
import { toast } from 'react-toastify';


const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResetSubmit = async (e) => {
        // console.log("SendReset link button is clicked");
        e.preventDefault(); // Prevent default form submission
        setErrorMessage(''); // Clear any previous errors

        if (!email) {
            setErrorMessage('Please enter a valid email address');
            toast.error('Please enter valid email address');
            return;


        }

        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address');
            toast.error('Please enter valid email address');

            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post('/api/v1/auth/forgotpassword', { email });
            if (response.data.success) {
                console.log('Reset password email sent successfully');
                // Optionally, display a success message to the user

                toast.success("Password reset email send successfully. Please check your email");
            } else {
                setErrorMessage(response.data.message || 'An error occurred');
                toast.error(response.data.message || 'An error occurred'); // Show error message directly
            }
        } catch (error) {
            console.error('Error sending reset password email:', error);
            setErrorMessage('An error occurred. Please try again later.');
            toast.error(error.message || 'An error occurred'); // Show error message directly

        } finally {
            setIsLoading(false);
        }
    };

    // ... validation logic (e.g., using a regular expression)


    const validateEmail = (email) => {
        return EmailValidator.validate(email);
    };

    return (
        <form form-container onSubmit={handleResetSubmit}>
            <input type="email" style={{ marginRight: '10px' }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder=" Enter your email address" />
            <button type="submit" style={{ marginBottom: '10px' }} className="btn btn-primary" disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send Reset Link'}
            </button>
            <div style={{ color: 'red' }}>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </form>
    );
}
export default ForgotPasswordForm;
