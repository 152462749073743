import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../../redux/features/alertSlice.js';
import axios from 'axios';
import { setUser } from '../../redux/features/auth/authSlice.js';
import { Navigate } from 'react-router-dom';

const CreatorRoute = ({ children }) => {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isCreator, setIsCreator] = useState(false);
    const [loading, setLoading] = useState(true);

    const getUser = async () => {
        try {
            dispatch(showLoading());
            const { data } = await axios.post(
                '/api/v1/user/getUser/',
                { token: localStorage.getItem('token') },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            dispatch(hideLoading());
            if (data.success) {
                dispatch(setUser(data.data));
                checkCreatorStatus(data.data._id);
            } else {
                localStorage.clear();
                setLoading(false);
            }
        } catch (error) {
            localStorage.clear();
            dispatch(hideLoading());
            setLoading(false);
            console.log(error);
        }
    };

    const checkCreatorStatus = async (userId) => {
        try {
            const { data } = await axios.get(`/api/v1/creatoraccount/getcreatoraccount?userId=${userId}`);
            setIsCreator(data.isCreator);

            console.log("isCreator is ", data.isCreator);

            // Store isCreator in both Redux and localStorage
            // dispatch(setIsCreator(data.isCreator));
            localStorage.setItem('isCreator', JSON.stringify(data.isCreator));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user) {
            getUser();
        } else {
            checkCreatorStatus(user._id);
        }
    }, [user]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (localStorage.getItem('token')) {
        if (isCreator) {
            return children;
        } else {
            return <Navigate to='/not-authorized' />;
        }
    } else {
        return <Navigate to='/login' />;
    }
};

export default CreatorRoute;
