import React, { useEffect, useState, useMemo } from 'react';
import Layout from '../components/Layout/Layout';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, IconButton } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getsciencedata } from '../redux/features/scienceSlice.js';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Spinner from '../components/shared/spinner.js';
import { formatTimeAgo } from '../utils/timeUtils.js';
// import ImageFiller from 'react-image-filler';
import { FavoriteSharp, Share, Email, Facebook, WhatsApp, X, LinkedIn } from '@mui/icons-material'; // Import icons
import axios from 'axios';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import CopyLinkButton from '../components/Layout/CopyLinkButton.js';
import { useQueryClient, useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '../components/theme-context.js';
import { Helmet } from 'react-helmet';






const Science = () => {

    const { isDarkMode } = useTheme();

    const queryClient = useQueryClient(); // Get the queryClient instance


    const { user } = useSelector((state) => state.auth);
    const userId = user?._id; // Assuming userId is stored in '_id' property

    // const [uniqueArticles, setUniqueArticles] = useState([]); // Stores filtered articles
    const [favoriteStatus, setFavoriteStatus] = useState([]); // Stores article IDs for liked articles
    const [isModalOpen, setIsModalOpen] = useState(false); // stores share icon state
    // const [isClicked, setIsClicked] = useState(false); // Track click state

    //****************************************************************************************************** */

    const dispatch = useDispatch();

    // const data = useSelector((state) => state.sciencedata);
    const { loading } = useSelector((state) => state.alerts);



    //***************************************************************************************************** */

    // Filter duplicates and remove logic moved here for clarity
    // useEffect(() => {
    //     if (data && data.articles) {
    //         const filteredArticles = data.articles.filter((article, index) => {
    //             return data.articles.findIndex((a) => a.title === article.title) === index;
    //         });

    //         //sorting code added on 12082024

    //         // Sort filteredArticles by date (assuming 'publishedAt' is the date property)
    //         const sortedArticles = filteredArticles.sort((a, b) => {
    //             return new Date(b.dateGmt) - new Date(a.dateGmt);
    //         });



    //         setUniqueArticles(sortedArticles);

    //     }
    // }, [data]);



    // Function to filter and sort articles
    const filterAndSortArticles = (articles) => {
        // console.log('Articles before filtering:', articles);
        const filteredArticles = articles.filter((article, index) => {
            return articles.findIndex((a) => a.title === article.title) === index;
        });
        // console.log('Articles after filtering:', filteredArticles);

        // Sort filteredArticles by date (assuming 'dateGmt' is the date property)
        const sortedArticles = filteredArticles.sort((a, b) => {
            return new Date(b.dateGmt) - new Date(a.dateGmt);
        });
        // console.log('Articles after sorting:', sortedArticles);

        return sortedArticles;
    };


    //******************************************************************************************************** */   
    //fetch favorites from api
    const fetchFavorites = async () => {

        dispatch(showLoading()); // Dispatch showLoading action

        try {

            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            if (userId) {
                const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=article`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                        }
                    }
                );
                const favoriteIds = response.data.data;

                // setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

                return response.data.data.map(article => article.articleId);

            }
        } catch (error) {
            dispatch(hideLoading()); // Dispatch hideLoading action

            console.error('Error fetching favorites:', error);
        } finally {
            dispatch(hideLoading()); // Dispatch hideLoading action
        }

    }

    //******************************************************************************************************** */   

    // Fetch data for Technology page (unchanged)
    // useEffect(() => {
    //     dispatch(getsciencedata());
    // }, [dispatch]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         dispatch(showLoading()); // Dispatch showLoading action

    //         try {
    //             await dispatch(getsciencedata());
    //         } catch (error) {
    //             // Handle error
    //         } finally {
    //             dispatch(hideLoading()); // Dispatch hideLoading action
    //         }
    //     };

    //     fetchData();
    // }, [dispatch]);
    //******************************************************************************************************** */
    //fetch tech data with react query

    const { data: scienceData = { payload: [] }, error: scienceError, isLoading: isScienceLoading } =

        useQuery('scienceData', async () => {

            dispatch(showLoading());


            try {
                const result = await dispatch(getsciencedata());

                // console.log('Home and Tech Data:', result); // Add this line

                return result;
            } catch (error) {
                // Handle error
                dispatch(hideLoading());
                toast.error("error occurred while fetching data from api");
                console.log("error occurred while loading data on Science page", error);
            } finally {
                dispatch(hideLoading()); // Dispatch hideLoading action
            }
        }, {
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes


        });



    //************************************************************************************************************** */

    // Fetch user's favorite articles during initial load
    // useEffect(() => {
    //     const fetchFavorites = async () => {
    //         dispatch(showLoading()); // Dispatch showLoading action

    //         try {
    //             if (userId) {
    //                 const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=article`);
    //                 const favoriteIds = response.data.data;
    //                 console.log(favoriteIds);
    //                 console.log("favorite article ids are ", favoriteIds);
    //                 // setFavoriteStatus(favoriteIds);
    //                 setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

    //             }
    //         } catch (error) {
    //             dispatch(hideLoading()); // Dispatch hideLoading action

    //             console.error('Error fetching favorites:', error);
    //         } finally {
    //             dispatch(hideLoading()); // Dispatch hideLoading action
    //         }
    //     };

    //     fetchFavorites();
    // }, [userId]);

    //************************************************************************************************************** */

    // load favorites with react-query

    const { data: favoriteIds = [], error: favoritesError, isLoading: isFavoritesLoading }
        = useQuery(['favorites', userId], async () => {


            dispatch(showLoading());
            try {
                const result = await fetchFavorites(userId);

                // console.log('Favorite IDs:', result); // Add this line
                setFavoriteStatus(result);

                return result;


            } finally {
                dispatch(hideLoading());
            }
        }, {
            enabled: !!userId, // Only run the query if userId is not null
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes
        });



    //************************************************************************************************** */   

    const handleClick = async (articleId, collectionName) => {
        // setIsClicked(true); // Update local state for UI feedback (optional)
        try {

            const clickData = {

                articleId,
                collectionName,
                articleType: 'article',
            };
            const response = await axios.post('/api/v1/clickcount/incrementclickcount?articleType=article', clickData);

            if (!response.ok) {
                throw new Error('Error incrementing click count');
            }

            console.log('Click count incremented successfully');
        } catch (error) {
            console.error('Error incrementing click count:', error);
        }
    };





    //******************************************************************************************************************* */    


    // Handle favorite click logic with client-side state update and server-side interaction
    const handleFavoriteClick = async (articleId, collectionName) => {
        // const articleId = article._id;
        const isAlreadyFavorited = favoriteStatus.includes(articleId);

        // Optimistic UI update: Toggle favorite color based on current state
        setFavoriteStatus((prevStatus) => (isAlreadyFavorited ? prevStatus.filter((id) => id !== articleId) : [...prevStatus, articleId]));

        // Update the favoriteIds in React Query cache
        queryClient.setQueryData(['favorites', userId], (oldData) =>
            isAlreadyFavorited ? oldData.filter((id) => id !== articleId) : [...oldData, articleId]
        );



        if (isAlreadyFavorited) {
            try {

                const token = localStorage.getItem('token'); // Retrieve the token from local storage

                const response = await axios.post(`/api/v1/favorite/deleteFavorite/${userId}/${articleId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }
                );
                if (response.success) {
                    console.log('Favorite deleted successfully!');
                    setFavoriteStatus((prevStatus) => prevStatus.filter((id) => id !== articleId));
                    // setFavoriteStatus(response.articleId);
                } else {
                    console.error('Error deleting favorite:', response.message);
                }
            } catch (error) {
                console.error('Error deleting favorite:', error);
            }
        } else {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token from local storage

                const favoriteData = {
                    userId,
                    articleId,
                    articleType: "article", // Or any other relevant type (modify if needed)
                    collectionName,
                };
                const response = await axios.post('/api/v1/favorite/createFavorite', favoriteData, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }
                );
                if (response.success) {
                    console.log('Favorite created successfully!');
                    setFavoriteStatus((prevStatus) => [...prevStatus, articleId]);
                    // setFavoriteStatus(response.articleId)
                } else {
                    console.error('Error creating favorite:', response.message);
                }
            } catch (error) {
                console.error('Error creating favorite:', error);
            }
        }
    };


    //************************************************************************************************************* */

    //************************************************************************************************************* */


    // const handleCopyLink = (ele) => {
    //     try {
    //         navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`);
    //         toast.success('Share link copied!', {
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     } catch (err) {
    //         console.error("Failed to copy link:", err);
    //         toast.error('Error copying link', {
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }
    // };


    //********************************************************************************** */
    const combinedData = useMemo(() => {
        // if (!favoriteIds.length || !scienceData.payload.length) return [];
        if (!scienceData.payload.length) return [];

        return scienceData.payload.map((ele) => ({
            ...ele,
            // isFavorite: favoriteIds.includes(ele._id),
            isFavorite: favoriteIds.length ? favoriteIds.includes(ele._id) : false,

        }));
    }, [favoriteIds, scienceData]);

    //********************************************************************************** */


    // Filter and sort articles
    // const uniqueArticles = scienceData && scienceData.payload ? filterAndSortArticles(scienceData.payload) : [];
    const uniqueArticles = combinedData.length > 0 ? filterAndSortArticles(combinedData) : [];



    //************************************************************************************************************* */

    if (isScienceLoading || isFavoritesLoading) {
        return <div className="loading-text">Loading...</div>;

        // return (
        //     <div className="loading-text">
        //         <ClipLoader color="#00adef" loading={true} size={25} />
        //         Loading...
        //     </div>
        // );
    }
    if (scienceError) {
        return <div className="loading-text">Error loading  science data</div>;
    }
    if (favoritesError) {
        return <div className="loading-text">Error loading favorites</div>;
    }




    return (



        <Layout>


            <Helmet>
                <title>Science</title>
                <meta
                    name="description"
                    content="Stay updated with the latest news in science. Get breaking tech news and articles from top sources."
                />
                <meta name="keywords" content="science news, science, technology news, AI, innovations, breaking news, " />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/science" />
            </Helmet>



            {
                loading ? (
                    <Spinner />
                ) : (
                    <>
                        <ToastContainer />

                        <div className={isDarkMode ? 'dark-mode' : ''}>


                            <Typography sx={{ ml: 3 }}>

                                <h5>Latest Science News</h5>

                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>




                                {/* {uniqueArticles.map((ele, index) => ( */}
                                {uniqueArticles.length > 0 ? (
                                    uniqueArticles.map((ele) => (


                                        <>

                                            {/* <Card sx={{ maxWidth: "390px", display: "flex", m: 2 }}> */}
                                            <Card key={ele._id} sx={{ maxWidth: 400, display: 'grid', gridTemplateRows: 'auto 1fr', m: 2 }}>




                                                {/* <CardActionArea href={ele.link} target="_blank"> */}
                                                {/* <CardActionArea onClick={handleClick(ele._id, ele.collectionName)} href={`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleclicks`} target="_blank"> */}
                                                <CardActionArea onClick={() => handleClick(ele._id, ele.collectionName)} href={`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleclicks`} target="_blank">

                                                    {ele.jetpackFeaturedMediaUrl ? (
                                                        <CardMedia
                                                            sx={{ minHeight: '250px', maxHeight: '250px' }}
                                                            component={'img'}
                                                            src={ele.jetpackFeaturedMediaUrl}
                                                            alt={ele.title}
                                                            onError={(e) => {
                                                                e.target.src = "/assets/images/Imagenotavailable.png"; // Use fallback image on error
                                                            }}


                                                        />
                                                    ) : (
                                                        // <ImageFiller width={400} height={200} text="Image not available" />
                                                        <img src="/assets/images/Imagenotavailable.png" alt="Image not available" width={400} height={200} />

                                                    )}

                                                    <CardContent className="CardContent">
                                                        <div>

                                                            <Typography variant="h6" gutterBottom component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '4.5em', lineHeight: '1.5em' }}>                                                {ele.title}

                                                            </Typography>
                                                            <Typography variant='body' >
                                                                {ele.provider}
                                                            </Typography>

                                                            <Typography variant='body2'>
                                                                {formatTimeAgo(ele.dateGmt)} ago
                                                            </Typography>
                                                        </div>

                                                    </CardContent>



                                                </CardActionArea>
                                                <div style={{ backgroundColor: 'var(--background-color)', color: 'var(--card-text-color)' }}>
                                                    <IconButton
                                                        aria-label="like"
                                                        onClick={() => handleFavoriteClick(ele._id, ele.collectionName)}
                                                        // sx={{ bgcolor: favoriteStatus.includes(ele._id) ? 'lightcoral' : 'transparent' }}>
                                                        sx={{ ml: 1 }}>

                                                        {/* {favoriteStatus.includes(ele._id) ? <Favorite sx={{ color: 'red' }} /> : <Favorite />} */}

                                                        {/* {favoriteStatus.includes(ele._id) ? (
                                                        <FavoriteSharp sx={{ color: 'red' }} /> // Set the color to red for filled heart
                                                    ) : (
                                                        <FavoriteSharp /> // Default (outline) heart
                                                    )} */}

                                                        {favoriteStatus.includes(ele._id) || ele.isFavorite ? (
                                                            <FavoriteSharp sx={{ color: 'red' }} />
                                                        ) : (
                                                            <FavoriteSharp />
                                                        )}
                                                    </IconButton>


                                                    {/* Add Share Button and Modal Logic Here */}
                                                    {/* <IconButton aria-label="share" onClick={() => setIsModalOpen(ele._id)}> */}
                                                    <IconButton aria-label="share" onClick={() => { setIsModalOpen(ele._id === isModalOpen ? false : ele._id); }}> {/* Toggle state on click */}

                                                        <Share />  {/* Optional: Icon for the share button */}
                                                    </IconButton>

                                                    {/* Modal Container (initially hidden) */}
                                                    {isModalOpen === ele._id && (
                                                        <div className="share-modal">
                                                            <h6 style={{ marginLeft: '5px', marginTop: '5px' }}>Share this article</h6>
                                                            <EmailShareButton url={ele.link} subject="Check out this article!" body="This is the article you should read.">
                                                                <Email size={32} round />
                                                            </EmailShareButton>
                                                            <FacebookShareButton url={ele.link} quote="This is a awesome article!">
                                                                <Facebook size={32} round />
                                                            </FacebookShareButton>
                                                            <WhatsappShareButton url={ele.link}>
                                                                <WhatsApp size={32} round />
                                                            </WhatsappShareButton>
                                                            <TwitterShareButton url={ele.link}>
                                                                <X size={32} round />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton url={ele.link}>
                                                                <LinkedIn size={32} round />
                                                            </LinkedinShareButton>
                                                            {/* <button onClick={() => navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`)}>Copy Link</button> */}
                                                            {/* <button onClick={() => handleCopyLink(ele)}>Copy Link</button> */}
                                                            <CopyLinkButton link={ele.link} />


                                                            {/* Add buttons for other platforms like Twitter, etc. */}
                                                            {/* <button onClick={() => setIsModalOpen(false)}>Close</button> */}
                                                        </div>
                                                    )}

                                                </div>



                                            </Card >

                                        </>
                                    ))

                                    //}

                                ) : (
                                    <div>No articles found or error loading articles, please check after some time</div>
                                )}

                            </Box>

                        </div>
                    </>
                )
            }
        </Layout >


    );
}


export default Science;
