import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getmobilitydata = createAsyncThunk("getmobilitydatathunk", async () => {

    const token = localStorage.getItem('token'); // Retrieve the token from local storage


    const response = await fetch("/api/v1/mobilitydata/getmobilitydata",

        {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        }
    );


    const result = await response.json();
    return result;
});

const mobilitySlice = createSlice({
    name: 'mobilitydata',
    initialState: {
        articles: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add any other reducers if needed

    extraReducers: (builder) => {
        builder
            .addCase(getmobilitydata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getmobilitydata.fulfilled, (state, action) => {
                state.loading = false;
                state.articles = action.payload;
            })
            .addCase(getmobilitydata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default mobilitySlice.reducer;
