import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getsciencedata = createAsyncThunk("getsciencedatathunk", async () => {

    const token = localStorage.getItem('token'); // Retrieve the token from local storage


    const response = await fetch("/api/v1/sciencedata/getsciencedata",

        {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        });

    const result = await response.json();
    return result;
});

const ScienceSlice = createSlice({
    name: 'sciencedata',
    initialState: {
        articles: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add any other reducers if needed

    extraReducers: (builder) => {
        builder
            .addCase(getsciencedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getsciencedata.fulfilled, (state, action) => {
                state.loading = false;
                state.articles = action.payload;
            })
            .addCase(getsciencedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default ScienceSlice.reducer;
