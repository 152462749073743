import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// export const getyoutubedata = createAsyncThunk("getyoutubedatathunk", async () => {

//     // export const getyoutubedata = createAsyncThunk("getyoutubedatathunk", async ({ pageNumber, pageSize }) => {

//     const response = await fetch("https://us-east-1.aws.data.mongodb-api.com/app/application-1-gvjvv/endpoint/getyoutubedata?secret=aX46TcMqt8CRHDxPfZLEbu4nySyUE");

//     const result = await response.json();
//     // return result.docs;
//     return result;
// });


export const getyoutubedata = createAsyncThunk("gettechdatathunk", async () => {

    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const response = await fetch("/api/v1/youtubedata/getyoutubedata",

        {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        }
    );




    const result = await response.json();
    return result;
});

const videoSlice = createSlice({
    name: 'youtubedata',
    initialState: {
        articles: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add any other reducers if needed

    extraReducers: (builder) => {
        builder
            .addCase(getyoutubedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getyoutubedata.fulfilled, (state, action) => {
                state.loading = false;
                state.articles = action.payload;
            })
            .addCase(getyoutubedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default videoSlice.reducer;
