import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store.js';
// import * as dotenv from 'dotenv';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { ThemeProvider } from './components/theme-context.js';


// dotenv.config();
// import { config } from 'dotenv';
// config();

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // const clientId = process.env.GOOGLE_CLIENT_ID; // Replace with your Google client ID


  <Provider store={store}>

    <BrowserRouter>
      <React.StrictMode>
        <GoogleOAuthProvider clientId="1073767726440-8ovp5gfb39qjhkqieoac7adqr92dk56u.apps.googleusercontent.com">
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </QueryClientProvider>

        </GoogleOAuthProvider>

      </React.StrictMode>
    </BrowserRouter>


  </Provider>



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
