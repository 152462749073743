import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getmarketresearchdata = createAsyncThunk("getmarketresearchdatathunk", async () => {

    const token = localStorage.getItem('token'); // Retrieve the token from local storage



    const response = await fetch("/api/v1/marketresearchdata/getmarketresearchdata",

        {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        }
    );

    const result = await response.json();
    return result;
});

const marketresearchSlice = createSlice({
    name: 'marketdata',
    initialState: {
        articles: [],
        loading: false,
        error: null,
    },
    reducers: {}, // Add any other reducers if needed

    extraReducers: (builder) => {
        builder
            .addCase(getmarketresearchdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getmarketresearchdata.fulfilled, (state, action) => {
                state.loading = false;
                state.articles = action.payload;
            })
            .addCase(getmarketresearchdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default marketresearchSlice.reducer;
