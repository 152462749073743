import React, { useState, useEffect } from 'react';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, colors, Divider } from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import '../../styles/HeaderStyle.css';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchBar from './Search';
import SearchIcon from "@mui/icons-material/Search";
// import Hidden from '@mui/icons-material';
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery for responsive styling

// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import ThemeToggleButton from '../ThemeToggleButton';
import Avatar from '@mui/material/Avatar';





const Header = () => {



    const [mobileOpen, setMobileOpen] = useState(false);
    const [useSearchBar, setUseSearchBar] = useState(false); // Default to false
    const navigate = useNavigate();

    const [profileOpen, setProfileOpen] = useState(false);

    const isMobile = useMediaQuery('(max-width: 768px)'); // Adjust breakpoint as needed

    // const { user, isCreator } = useSelector((state) => state.auth);


    // Fetch isCreator from Redux
    const { user, isCreator: reduxIsCreator } = useSelector((state) => state.auth);

    // Fetch isCreator from localStorage
    const [isCreator, setIsCreator] = useState(reduxIsCreator || JSON.parse(localStorage.getItem('isCreator')) || false);

    const exsitingprofileImageUrl = user?.profileImageUrl;
    // const exsitingprofileImageUrl = ''


    // Update isCreator state when Redux changes
    useEffect(() => {
        if (reduxIsCreator !== undefined) {
            setIsCreator(reduxIsCreator);
        }
    }, [reduxIsCreator]);



    const TopAppBar = () => {

        // // Ensure setUseSearchBar is passed as a prop
        // if (!setUseSearchBar) {
        //     throw new Error('TopAppBar requires a setUseSearchBar function as a prop.');
        // }



        // const isMobile = useMediaQuery < boolean > ('(max-width: 600px)');
        //************************************************************************************ */ 

        return (
            <AppBar position="static" style={{ backgroundColor: '#3498db' }}>
                <Toolbar variant="dense">
                    <IconButton color='inherit' aria-label='open drawer' edge='start'
                        sx={
                            {
                                mr: 2,
                                display: { sm: "none" }

                            }

                        }
                        onClick={handleDrawerToggle}

                    >

                        <MenuRoundedIcon />
                    </IconButton>

                    <Link to="/dashboard">

                        <img src='/assets/images/HeshMoreLogo.png' alt='HeshMore Logo' height={'35'} width={'35'} />

                    </Link>
                    <Typography
                        color={"white"}
                        variant='h6'
                        component={'div'}
                        sx={{ flexGrow: 1, ml: 2, fontSize: isMobile ? '1.5rem' : '1.75rem' }}
                    >
                        HeshMore App
                    </Typography>


                    {/* Search Icon (using SearchBar component) */}



                    {!isMobile && (

                        useSearchBar ? <SearchBar /> : (
                            <IconButton color="inherit" aria-label="search"



                                onClick={() => setUseSearchBar(true)}>
                                <SearchIcon />
                            </IconButton >
                        )

                    )}




                    <ul className='navigation-menu-top'>
                        <li style={{ marginTop: '15px' }}> {/* Adjust margin as needed */}
                            {/* <NavLink

                                activeClassName="profilemenuitem" to='/dashboard'>Profile</NavLink> */}
                            {/* <button aria-label='open profile drawer' edge='start' onClick={handleprofileDrawerToggle}>Profile</button> */}
                            {/* <span
                                onClick={handleprofileDrawerToggle}
                                aria-label='open profile drawer'
                                edge='end'
                                style={{ cursor: 'pointer' }}>
                                Profile
                            </span> */}

                            {/* <IconButton onClick={handleprofileDrawerToggle}

                                sx={{

                                    height: '40px',
                                    fontSize: isMobile ? 'inherit' : '3rem' // Adjust sizes as needed
                                }}
                            >
                                <AccountCircleOutlinedIcon sx={{ alignSelf: 'center', mt: 1 }} />
                            </IconButton> */}




                            <IconButton
                                onClick={handleprofileDrawerToggle}
                                sx={{
                                    height: '40px',
                                    fontSize: isMobile ? 'inherit' : '3rem', // Adjust sizes as needed
                                    padding: 0
                                }}
                            >
                                <Avatar
                                    src={exsitingprofileImageUrl}
                                    alt="Profile"
                                    sx={{ width: 50, height: 50 }} // Adjust size as needed
                                >
                                    {!exsitingprofileImageUrl && <AccountCircleOutlinedIcon />}
                                </Avatar>
                            </IconButton>
                        </li>
                    </ul>

                    <Box component='profilenav'>
                        <Drawer
                            variant='temporary'
                            open={profileOpen}
                            onClose={handleprofileDrawerToggle}



                        >
                            {profiledrawer}
                        </Drawer>

                    </Box>



                </Toolbar>
            </AppBar >
        );
    };


    //************************************************************************************ */ 

    const BottomAppBar = () => {
        return (
            <AppBar position="sticky" top={0} style={{ backgroundColor: 'black' }}>
                <Toolbar variant="dense">

                    {isMobile && (

                        useSearchBar ? <SearchBar /> : (
                            <IconButton color="inherit" aria-label="search"



                                onClick={() => setUseSearchBar(true)}>
                                <SearchIcon />
                            </IconButton >
                        )

                    )}


                    <Box sx={{ display: { xs: 'none', sm: 'block' }, mt: 1.5 }}>
                        <ul className='navigation-menu'>
                            <li>
                                <NavLink activeClassName="active" to='/dashboard'>Home</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to='/technology'>Technology</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to='/videos'>Videos</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" to='/science'>Science</NavLink>
                            </li>

                            <li>
                                <NavLink activeClassName="active" to='/mobilitytechnology'>Mobility Technology</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to='/marketresearch'>Market Research</NavLink>
                            </li>
                            <li>
                                <Link to="/login" onClick={() => {
                                    localStorage.clear(); // Clear all local storage
                                    navigate('/login'); // Redirect to login after logout
                                }}>Logout</Link>
                            </li>
                        </ul>



                    </Box>



                </Toolbar>

            </AppBar >
        );
    };


    //************************************************************************************ */ 

    // handle menu click

    // hndle menu click
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    //************************************************************************************ */ 

    //handle profile click drawer

    const handleprofileDrawerToggle = () => {

        setProfileOpen(!profileOpen);

    }

    //************************************************************************************ */ 


    //menu drawer

    const drawer = (

        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: 'black' }}>
            <img src='/assets/images/HeshMoreLogo.png' alt='HeshMore Logo' height='45' width='45' style={{ marginTop: "10px" }} />

            <Typography
                color={"#2faae3"}
                variant='h6'
                component={'div'}
                sx={{ flexGrow: 1, my: 2 }}
            >
                HeshMore App
            </Typography>
            <Divider style={{ height: 4, backgroundColor: 'white' }} />  // Change '#f00' to your desired color (e.g., red)

            <ul className='mobile-navigation-menu'>
                <li>
                    <NavLink activeClassName="active" to='/dashboard'>Home</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/technology'>Technology</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/videos'>Videos</NavLink>
                </li>

                <li>
                    <NavLink activeClassName="active" to='/science'>Science</NavLink>
                </li>

                <li>
                    <NavLink activeClassName="active" to='/mobilitytechnology'>Mobility Technology</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/marketresearch'>Market Research</NavLink>
                </li>
                <li>
                    <NavLink to="/login" onClick={() => {
                        localStorage.clear(); // Clear all local storage
                        navigate('/login'); // Redirect to login after logout

                    }}>Logout</NavLink>
                </li>
            </ul>



        </Box>

    );


    const profiledrawer = (

        <Box onClick={handleprofileDrawerToggle}
            sx={{
                textAlign: 'center',
                backgroundColor: 'var(--background-color)',
                color: 'var(--text-color)',
                width: '300px'
            }}>
            <img src='/assets/images/HeshMoreLogo.png' alt='HeshMore Logo' height='45' width='45' style={{ marginTop: "10px" }} />

            <Typography
                color={"#2faae3"}
                variant='h6'
                component={'div'}
                sx={{
                    flexGrow: 1,
                    my: 2,
                    color: 'var(--text-color)',

                }}
            >
                HeshMore App
            </Typography>
            <Divider style={{ height: 4, backgroundColor: 'var(--text-color)' }} />

            <ul className='mobile-navigation-menu'>
                <li>
                    <NavLink activeClassName="active" to='/profile'>Profile</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/favoritearticles'>Favorites</NavLink>

                    <ul className='submenu'>
                        <li>
                            <NavLink activeClassName="active" to='/favoritearticles'>Favorite Articles</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to='/favoritevideos'>Favorite Videos</NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/trendingarticles'>Trending</NavLink>
                    <ul className='submenu'>
                        <li>
                            <NavLink activeClassName="active" to='/trendingarticles'>Trending Articles</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to='/trendingvideos'>Trending Videos</NavLink>
                        </li>
                    </ul>
                </li>

                {isCreator && (
                    <>
                        <li>
                            <NavLink activeClassName="active" to='/uploadarticle'>Upload Article</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to='/userarticles'>Your Articles</NavLink>
                        </li>
                    </>
                )}
                {/* <li>
                    <NavLink activeClassName="active" to='/uploadarticle'>Upload Article</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/userarticles'>Your Articles</NavLink>
                </li> */}



                <li>
                    <NavLink to="/login" onClick={() => {
                        localStorage.clear(); // Clear all local storage
                        navigate('/login'); // Redirect to login after logout

                    }}>Logout</NavLink>
                </li>
                <li>
                    <ThemeToggleButton /> {/* Add the toggle button here */}
                </li>
            </ul>



        </Box>

    );

    return (
        <>


            <Box>
                {/* <TopAppBar className="top-appbar" /> */}

                <TopAppBar className="top-appbar" />

                <BottomAppBar className="bottom-appbar" />








                <Box component='nav'>
                    <Drawer
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        sx={{
                            display: { xs: 'block', sm: "none" }, "& .MuiDrawer-paper": {

                                boxSizing: 'border-box',
                                width: "300px",

                            }
                        }}

                    >
                        {drawer}
                    </Drawer>

                </Box>



                <Box>
                    <Toolbar />

                </Box>

            </Box>
        </>
    )

}

export default Header
