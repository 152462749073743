import React, { useState } from "react";
import { InputBase, IconButton, Badge } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import "../../styles/SearchBar.css"

const SearchBar = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchClick = () => {
        if (searchTerm) {
            navigate(`/searchpage?q=${searchTerm}`); // Navigate to search page with query param
        }
    };

    return (
        <Badge badgeContent={0} color="secondary">
            <InputBase
                className="search-bar" // Apply the custom class
                sx={{ ml: 2, flex: 1, bgcolor: "white" }} // Set background color to white
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                endAdornment={<IconButton type="submit" aria-label="search" onClick={handleSearchClick}><SearchIcon /></IconButton>}
            />
        </Badge>
    );
};

export default SearchBar;
