import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import Layout from '../components/Layout/Layout';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, IconButton } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getyoutubedata } from '../redux/features/videosSlice.js';
import { hideLoading, showLoading } from '../redux/features/alertSlice.js';
import Spinner from '../components/shared/spinner.js';
import { formatTimeAgo } from '../utils/timeUtils.js';
import ImageFiller from 'react-image-filler';
import { FavoriteSharp, Share, Email, Facebook, WhatsApp, X, LinkedIn } from '@mui/icons-material'; // Import icons
import axios from 'axios';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import CopyLinkButton from '../components/Layout/CopyLinkButton.js';
import { useQueryClient, useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import parse from 'html-react-parser';
import he from 'he';
import YouTube from 'react-youtube'; // Install using npm install react-youtube


import { useTheme } from '../components/theme-context.js';
import { Helmet } from 'react-helmet';




const Videos = () => {

    // const [isloading, setisLoading] = useState(true);


    const [playerloading, setplayerLoading] = useState(false); // State to track loading


    const playerRefs = useRef({}); // Object to store individual player references


    const [playingVideoId, setPlayingVideoId] = useState(null); // State to track which video is playing

    // const handlePlayVideo = (e) => {
    //     e.stopPropagation(); // Prevent the CardActionArea click event
    //     e.preventDefault();


    //     setIsVideoPlaying(true);
    // };




    // const playVideo = useCallback((videoId) => {
    //     setPlayingVideoId(videoId);
    //     setplayerLoading(prev => ({ ...prev, [videoId]: true }));
    // }, []);




    // const onReady = useCallback((event, videoId) => {
    //     playerRefs.current[videoId] = event.target;
    //     setplayerLoading(prev => ({ ...prev, [videoId]: false }));
    // }, []);



    // const onStateChange = useCallback((event) => {
    //     if (event.data === YouTube.PlayerState.PLAYING) {
    //         setplayerLoading(prev => ({ ...prev, [event.target.getVideoData().video_id]: false }));
    //     }
    // }, []);


    // // Simulating data loading
    // React.useEffect(() => {
    //     setTimeout(() => setisLoading(false), 1000);
    // }, []);





    const { isDarkMode } = useTheme();


    const queryClient = useQueryClient(); // Get the queryClient instance


    const { user } = useSelector((state) => state.auth);
    const userId = user?._id; // Assuming userId is stored in '_id' property

    // const [uniqueArticles, setUniqueArticles] = useState([]); // Stores filtered articles
    const [favoriteStatus, setFavoriteStatus] = useState([]); // Stores article IDs for liked articles
    const [isModalOpen, setIsModalOpen] = useState(false); // stores share icon state
    // const [isClicked, setIsClicked] = useState(false); // Track click state

    //****************************************************************************************************** */

    const dispatch = useDispatch();

    // const data = useSelector((state) => state.videodata);
    const { loading } = useSelector((state) => state.alerts);



    //***************************************************************************************************** */

    // Filter duplicates and remove logic moved here for clarity
    // useEffect(() => {
    //     if (data && data.articles) {
    //         const filteredArticles = data.articles.filter((article, index) => {
    //             return data.articles.findIndex((a) => a.title === article.title) === index;
    //         });
    //         //sorting code added on 12082024

    //         // Sort filteredArticles by date (assuming 'publishedAt' is the date property)
    //         const sortedArticles = filteredArticles.sort((a, b) => {
    //             return new Date(b.dateGmt) - new Date(a.dateGmt);
    //         });



    //         setUniqueArticles(sortedArticles);
    //     }
    // }, [data]);


    // Function to filter and sort articles
    const filterAndSortArticles = (articles) => {
        // console.log('Articles before filtering:', articles);
        const filteredArticles = articles.filter((article, index) => {
            return articles.findIndex((a) => a.title === article.title) === index;
        });
        // console.log('Articles after filtering:', filteredArticles);

        // Sort filteredArticles by date (assuming 'dateGmt' is the date property)
        const sortedArticles = filteredArticles.sort((a, b) => {
            return new Date(b.dateGmt) - new Date(a.dateGmt);
        });
        // console.log('Articles after sorting:', sortedArticles);

        return sortedArticles;
    };

    //******************************************************************************************************** */   
    //fetch favorites from api
    const fetchFavorites = async () => {

        dispatch(showLoading()); // Dispatch showLoading action

        try {
            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            if (userId) {
                const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=video`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                        }
                    }
                );
                const favoriteIds = response.data.data;

                // setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

                return response.data.data.map(article => article.articleId);

            }
        } catch (error) {
            dispatch(hideLoading()); // Dispatch hideLoading action

            console.error('Error fetching favorites:', error);
        } finally {
            dispatch(hideLoading()); // Dispatch hideLoading action
        }

    }


    //********************************************************************************************************


    //******************************************************************************************************** */   

    // useEffect(() => {
    //     dispatch(getyoutubedata());
    // }, [dispatch]);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         dispatch(showLoading()); // Dispatch showLoading action

    //         try {
    //             await dispatch(getyoutubedata());
    //         } catch (error) {
    //             // Handle error
    //         } finally {
    //             dispatch(hideLoading()); // Dispatch hideLoading action
    //         }
    //     };

    //     fetchData();
    // }, [dispatch]);


    //fetch youtube data with react query

    const { data: youtubeData = { payload: [] }, error: youtubeError, isLoading: isYoutubeLoading } =

        useQuery('youtubeData', async () => {

            dispatch(showLoading());


            try {
                const result = await dispatch(getyoutubedata());

                // console.log('Home and Tech Data:', result); // Add this line

                return result;
            } catch (error) {
                // Handle error
                dispatch(hideLoading());
                toast.error("error occurred while fetching data from api");
                console.log("error occurred while loading data on Videos page", error);
            } finally {
                dispatch(hideLoading()); // Dispatch hideLoading action
            }
        }, {
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes


        });


    //************************************************************************************************************** */



    //************************************************************************************************************** */

    // Fetch user's favorite articles during initial load
    // useEffect(() => {
    //     const fetchFavorites = async () => {

    //         dispatch(showLoading()); // Dispatch showLoading action

    //         try {
    //             if (userId) {
    //                 const response = await axios.get(`/api/v1/favorite/getFavorite/${userId}?articleType=video`);
    //                 const favoriteIds = response.data.data;
    //                 console.log(favoriteIds);
    //                 console.log("favorite article ids are ", favoriteIds);
    //                 // setFavoriteStatus(favoriteIds);
    //                 setFavoriteStatus(favoriteIds.map(article => article.articleId)); // Update favoriteStatus with article IDs

    //             }
    //         } catch (error) {
    //             dispatch(hideLoading()); // Dispatch hideLoading action

    //             console.error('Error fetching favorites:', error);
    //         } finally {
    //             dispatch(hideLoading()); // Dispatch hideLoading action
    //         }
    //     };

    //     fetchFavorites();
    // }, [userId]);


    // load favorites with react-query

    const { data: favoriteIds = [], error: favoritesError, isLoading: isFavoritesLoading }
        = useQuery(['favorites', userId], async () => {

            dispatch(showLoading());
            try {
                const result = await fetchFavorites(userId);

                // console.log('Favorite IDs:', result); // Add this line

                setFavoriteStatus(result);
                return result;


            } finally {
                dispatch(hideLoading());
            }
        }, {
            enabled: !!userId, // Only run the query if userId is not null
            staleTime: 1800000, // 15 minutes
            cacheTime: 900000, // 10 minutes
        });

    //************************************************************************************************************** */

    const combinedData = useMemo(() => {
        // if (!favoriteIds.length || !youtubeData.payload.length) return [];
        if (!youtubeData.payload.length) return [];


        // Filter out articles with missing _id or other required fields
        const validArticles = youtubeData.payload.filter(ele => ele._id && ele.title);



        return validArticles.map((ele) => ({

            // return youtubeData.payload.map((ele) => ({
            ...ele,
            // isFavorite: favoriteIds.includes(ele._id),
            isFavorite: favoriteIds.length ? favoriteIds.includes(ele._id) : false,

        }));
    }, [favoriteIds, youtubeData]);


    //************************************************************************************************** */   

    const handleClickCount = async (articleId, collectionName) => {
        // setIsClicked(true); // Update local state for UI feedback (optional)
        try {

            const clickData = {

                articleId,
                collectionName,
                articleType: 'video',
            };
            const response = await axios.post('/api/v1/clickcount/incrementclickcount?articleType=video', clickData);

            if (!response.ok) {
                throw new Error('Error incrementing click count');
            }

            console.log('Click count incremented successfully');
        } catch (error) {
            console.error('Error incrementing click count:', error);
        }
    };





    //******************************************************************************************************************* */    


    // Handle favorite click logic with client-side state update and server-side interaction
    const handleFavoriteClick = async (articleId, collectionName) => {
        // const articleId = article._id;
        const isAlreadyFavorited = favoriteStatus.includes(articleId);

        // Optimistic UI update: Toggle favorite color based on current state
        setFavoriteStatus((prevStatus) => (isAlreadyFavorited ? prevStatus.filter((id) => id !== articleId) : [...prevStatus, articleId]));



        // Update the favoriteIds in React Query cache
        queryClient.setQueryData(['favorites', userId], (oldData) =>
            isAlreadyFavorited ? oldData.filter((id) => id !== articleId) : [...oldData, articleId]
        );


        if (isAlreadyFavorited) {
            const token = localStorage.getItem('token'); // Retrieve the token from local storage

            try {
                const response = await axios.post(`/api/v1/favorite/deleteFavorite/${userId}/${articleId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }

                );
                if (response.success) {
                    console.log('Favorite deleted successfully!');
                    setFavoriteStatus((prevStatus) => prevStatus.filter((id) => id !== articleId));
                    // setFavoriteStatus(response.articleId);
                } else {
                    console.error('Error deleting favorite:', response.message);
                }
            } catch (error) {
                console.error('Error deleting favorite:', error);
            }
        } else {
            try {

                const token = localStorage.getItem('token'); // Retrieve the token from local storage

                const favoriteData = {
                    userId,
                    articleId,
                    articleType: "video", // Or any other relevant type (modify if needed)
                    collectionName,
                };
                const response = await axios.post('/api/v1/favorite/createFavorite', favoriteData, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                }
                );
                if (response.success) {
                    console.log('Favorite created successfully!');
                    setFavoriteStatus((prevStatus) => [...prevStatus, articleId]);
                    // setFavoriteStatus(response.articleId)
                } else {
                    console.error('Error creating favorite:', response.message);
                }
            } catch (error) {
                console.error('Error creating favorite:', error);
            }
        }
    };


    //************************************************************************************************************* */

    //********************************************************************************** */
    // Filter and sort articles
    // const uniqueArticles = youtubeData && youtubeData.payload ? filterAndSortArticles(youtubeData.payload) : [];


    const uniqueArticles = combinedData.length > 0 ? filterAndSortArticles(combinedData) : [];

    //************************************************************************************************************* */

    if (isYoutubeLoading || isFavoritesLoading) {
        return <div className="loading-text">Loading...</div>;

        // return (
        //     <div className="loading-text">
        //         <ClipLoader color="#00adef" loading={true} size={25} />
        //         Loading...
        //     </div>
        // );
    }
    if (youtubeError) {
        return <div className="loading-text">Error loading  youtube data</div>;
    }
    if (favoritesError) {
        return <div className="loading-text">Error loading favorites</div>;
    }


    //************************************************************************************************* */
    const onReady = (event, videoId) => {
        playerRefs.current[videoId] = event.target; // Store the player reference
        event.target.playVideo(); // Play the video when the player is ready
        setplayerLoading(false);

    };


    //*************************************************************************************************
    // const playVideo = (videoId) => {
    //     if (playerRefs.current[videoId]) {
    //         playerRefs.current[videoId].playVideo(); // Play the video using the stored reference
    //         setPlayingVideoId(videoId); // Set the currently playing video ID
    //     } else {
    //         console.warn(`Player reference not found for video ID: ${videoId}`);
    //     }
    // };

    const playVideo = (videoId, articleId, collectionName) => {
        setPlayingVideoId(videoId); // Set the currently playing video ID
        setplayerLoading(true);
        // Call handleClickCount to increment the click count for this video
        handleClickCount(articleId, collectionName);
    };




    //****************************************************************************************************** */
    return (



        <Layout>

            <Helmet>
                <title>Videos</title>
                <meta
                    name="description"
                    content="Stay updated with the latest video news in technology, gadgets, AI, and innovations. Get breaking tech news and articles from top sources."
                />
                <meta name="keywords" content="technology news, gadgets, AI, innovations, breaking news, science news, gaming, gaming news" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/videos" />
            </Helmet>




            {
                loading ? (
                    <Spinner />
                ) : (
                    <>

                        <div className={isDarkMode ? 'dark-mode' : ''}>

                            <Typography sx={{ ml: 3 }}>

                                <h5>Latest Video News</h5>

                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>




                                {uniqueArticles.length > 0 ? (
                                    uniqueArticles.map((ele) => (

                                        <>


                                            <Card key={ele._id} sx={{ width: '100%', maxWidth: 400, display: 'grid', gridTemplateRows: 'auto 1fr', m: 2 }}>


                                                <CardActionArea onClick={() => playVideo(ele.link, ele._id, ele.collectionName)}>

                                                    {loading && <div className="loading-spinner">Loading...</div>}

                                                    {playingVideoId === ele.link ? (
                                                        <>

                                                            <YouTube
                                                                videoId={ele.link}
                                                                onReady={(event) => onReady(event, ele.link)}
                                                                opts={{
                                                                    // height: '250',
                                                                    // width: '400',
                                                                    width: '100%',  // Responsive width
                                                                    height: '225px', // Set a fixed height that matches the thumbnail height
                                                                    playerVars: {
                                                                        autoplay: 0,
                                                                    },
                                                                }}
                                                                style={{
                                                                    aspectRatio: '16/9',  // Maintain a 16:9 aspect ratio for the video
                                                                    // width: '100%',
                                                                    // height: '250px',  // Ensure the video height matches thumbnail
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="thumbnail" style={{ position: 'relative', width: '100%', height: 'auto', aspectRatio: '16/9' }}>
                                                            <img src={ele.jetpackFeaturedMediaUrl}
                                                                alt={ele.title}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover', // This will ensure the image fits within the container without distortion
                                                                }}

                                                            />
                                                            <PlayButtonOverlay />
                                                        </div>
                                                    )}
                                                </CardActionArea>


                                                <CardContent className="CardContent">
                                                    <div>

                                                        <Typography variant="h6"

                                                            gutterBottom component="div"
                                                            sx={{
                                                                // // overflow: 'hidden',
                                                                // // textOverflow: 'ellipsis',
                                                                // wordWrap: 'break-word',
                                                                // // maxHeight: '4.5em', 
                                                                // lineHeight: '1.5em'
                                                                wordWrap: 'break-word',
                                                                wordBreak: 'break-word',  // Ensures text breaks to the next line
                                                                whiteSpace: 'normal',     // Allows text wrapping
                                                                lineHeight: '1.5em',
                                                            }}>
                                                            {parse(he.decode(ele.title))}

                                                        </Typography>
                                                        <Typography variant='body' >
                                                            {ele.provider}
                                                        </Typography>

                                                        <Typography variant='body2'>
                                                            {formatTimeAgo(ele.dateGmt)} ago
                                                        </Typography>
                                                    </div>

                                                </CardContent>

                                                <div style={{ backgroundColor: 'var(--background-color)', color: 'var(--card-text-color)' }}>
                                                    <IconButton
                                                        aria-label="like"
                                                        onClick={() => handleFavoriteClick(ele._id, ele.collectionName)}
                                                        sx={{ ml: 1 }}>


                                                        {favoriteStatus.includes(ele._id) || ele.isFavorite ? (
                                                            <FavoriteSharp sx={{ color: 'red' }} />
                                                        ) : (
                                                            <FavoriteSharp />
                                                        )}
                                                    </IconButton>


                                                    <IconButton aria-label="share" onClick={() => { setIsModalOpen(ele._id === isModalOpen ? false : ele._id); }}> {/* Toggle state on click */}

                                                        <Share />
                                                    </IconButton>

                                                    {/* Modal Container (initially hidden) */}
                                                    {isModalOpen === ele._id && (
                                                        <div className="share-modal">
                                                            <h6 style={{ marginLeft: '5px', marginTop: '5px' }}>Share this video</h6>
                                                            <EmailShareButton url={`https://www.youtube.com/watch?v=${ele.link}`} subject="Check out this video!" body="This is the video you should watch.">
                                                                <Email size={32} round />
                                                            </EmailShareButton>
                                                            <FacebookShareButton url={`https://www.youtube.com/watch?v=${ele.link}`} quote="Check out this video!">
                                                                <Facebook size={32} round />
                                                            </FacebookShareButton>
                                                            <WhatsappShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <WhatsApp size={32} round />
                                                            </WhatsappShareButton>
                                                            <TwitterShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <X size={32} round />
                                                            </TwitterShareButton>
                                                            <LinkedinShareButton url={`https://www.youtube.com/watch?v=${ele.link}`}>
                                                                <LinkedIn size={32} round />
                                                            </LinkedinShareButton>
                                                            {/* <button onClick={() => navigator.clipboard.writeText(`${ele.link}?utm_source=HeshMoreApp&utm_medium=heshmoreappwebsite&utm_campaign=articleshare`)}>Copy Link</button> */}
                                                            <CopyLinkButton link={`https://www.youtube.com/watch?v=${ele.link}`} />


                                                            {/* Add buttons for other platforms like Twitter, etc. */}
                                                            {/* <button onClick={() => setIsModalOpen(false)}>Close</button> */}
                                                        </div>
                                                    )}

                                                </div>



                                            </Card >

                                        </>
                                    ))

                                    //}

                                ) : (
                                    <div>No articles found or error loading articles, please login again or check after some time</div>
                                )}

                            </Box >
                        </div>
                    </>
                )
            }
        </Layout >

    );
}


const PlayButtonOverlay = () => {
    return (
        <div className="play-button-overlay">
            <img src="/assets/images/youtubeplaybutton.png" alt="Play Button" />

        </div>
    );
};
export default Videos;
