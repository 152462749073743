import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you're using Axios for API calls
import { toast } from 'react-toastify';
import Inputform from '../components/shared/inputform';
import { Link, useNavigate } from 'react-router-dom';



const ResetPassword = () => {

    const navigate = useNavigate();

    const [verificationStatus, setVerificationStatus] = useState(null);


    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);




    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        if (token) {
            axios.get(`/api/v1/auth/verfiypasswordtoken?token=${token}`)
                .then(response => {
                    setVerificationStatus(response.data);
                })
                .catch(error => {
                    // Check for specific error message indicating expired link
                    if (error.response && error.response.data.message === 'Password Reset link expired.') {
                        setError(error.response.data.message); // Set the specific error message
                    } else {
                        // Handle other errors
                        setError(error.response.data.message); // Set the specific error message

                        console.error(error); // Log other errors for debugging
                    }
                });
        }
    }, []);



    //*************************************************************************************************** */


    const handleResetPasswordSubmit = async (e) => {


        e.preventDefault(); // Prevent default form submission
        setErrorMessage(''); // Clear any previous errors
        setIsLoading(true);

        if (!confirmpassword || !password) {
            toast.error('Please provide valid password');
            setErrorMessage('Please enter a valid password');

            return;
        }

        if (password !== confirmpassword) {
            toast.error('Passwords do not match');
            setErrorMessage('Passwords do not match');
            return;
        }


        const token = new URLSearchParams(window.location.search).get('token');
        const data = { passwordResetToken: token, password };



        try {

            const response = await axios.put('/api/v1/user/updatepassword', data);
            console.log(response);

            if (response.data.success) {
                // console.log('Reset password email sent successfully');
                // Optionally, display a success message to the user

                toast.success(response.data.message);

                navigate('/login');

            } else {
                setErrorMessage(response.data.message || 'An error occurred');
                toast.error(response.data.message || 'An error occurred'); // Show error message directly
            }



        } catch (error) {
            setIsLoading(false);
            console.error(error);
            toast.error('An error occurred. Please try again later.');
        }


    }

    return (

        <div className='form-container'>
            <h4 className="">Reset Password</h4>
            <br></br>


            <form form-container onSubmit={handleResetPasswordSubmit}>
                <div className='imagelogo'>
                    <img src='/assets/images/HeshMoreLogo.png' alt='HeshMoreApp Logo' height={50} width={50}></img>
                </div>
                <h5 className="text-center text-primary">HeshMore App</h5>



                {verificationStatus && verificationStatus.success ? (




                    <>
                        <Inputform htmlFor="password"

                            labeltext={'Password:'}
                            type={'password'}
                            value={password}
                            handlechange={(e) => setPassword(e.target.value)}
                            name="password" /><Inputform htmlFor="password"

                                labeltext={'Confirm Password:'}
                                type={'password'}
                                value={confirmpassword}
                                handlechange={(e) => setConfirmPassword(e.target.value)}
                                name="password" /><button type="submit" className="btn btn-primary">Submit</button>
                    </>

                ) : error ? (
                    <div style={{ color: 'red' }}>
                        <p>Error: {error}</p>
                        {/* <button onClick={handleResend} disabled={resendDisabled}>
                            {resendDisabled ? 'Resend disabled' : 'Resend Verification Email'}
                        </button> */}
                    </div>
                ) : (
                    <p>Verifying ...{error}</p>
                )}


            </form>
        </div>

    )
}

export default ResetPassword
