import { configureStore } from '@reduxjs/toolkit';
import { alertSlice } from './features/alertSlice';
import { authSlice } from './features/auth/authSlice';
import homeandtechSlice from './features/homeandtechSlice';
import scienceSlice from './features/scienceSlice';
import technologySlice from './features/technologySlice';
import mobilitySlice from './features/mobilitySlice';
import marketresearchSlice from './features/marketresearchSlice.js';
import videosSlice from './features/videosSlice.js';

export default configureStore({


    reducer: {

        alerts: alertSlice.reducer,
        auth: authSlice.reducer,
        homepagedata: homeandtechSlice,
        sciencedata: scienceSlice,
        techdata: technologySlice,
        mobilitydata: mobilitySlice,
        marketdata: marketresearchSlice,
        videodata: videosSlice,
    },
});

