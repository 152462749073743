import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({

    name: "auth",
    initialState: {

        user: null,
        // isCreator: false,
        isCreator: JSON.parse(localStorage.getItem('isCreator')) || false,


    },
    reducers: {

        setUser: (state, action) => {

            state.user = action.payload;
        },

        setCreatorStatus: (state, action) => {
            state.isCreator = action.payload;
        },
        clearUser: (state) => {
            state.user = null;
            state.isCreator = false;
            localStorage.removeItem('isCreator');
        },

    },



});

export const { setUser, setCreatorStatus } = authSlice.actions;


//***************************************************************************************** */


// const initialState = {
//     user: JSON.parse(localStorage.getItem('user')) || null,
// };

// export const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         setUser: (state, action) => {
//             state.user = action.payload;
//             localStorage.setItem('user', JSON.stringify(action.payload));
//         },
//         clearUser: (state) => {
//             state.user = null;
//             localStorage.removeItem('user');
//         },
//     },
// });

// export const { setUser, clearUser } = authSlice.actions;
// export default authSlice.reducer;


//**************************************************************************************************************




// const initialState = {
//     user: null,
//     loading: false, // Add loading state to indicate data fetching progress
//     error: null, // Add error state to handle potential fetching errors
// };

// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         setUser: (state, action) => {
//             state.user = action.payload;
//         },
//         // Add actions for fetching user data
//         fetchUserDataRequest: (state) => {
//             state.loading = true;
//             state.error = null; // Reset error on new request
//         },
//         fetchUserDataSuccess: (state, action) => {
//             state.loading = false;
//             state.user = action.payload;
//         },
//         fetchUserDataFailure: (state, action) => {
//             state.loading = false;
//             state.error = action.payload;
//         },
//     },
// });

// export const { setUser, fetchUserDataRequest, fetchUserDataSuccess, fetchUserDataFailure } = authSlice.actions;
// export default authSlice.reducer;