// CopyLinkButton.js
import React, { useState } from 'react';

function CopyLinkButton({ link }) {
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setIsLinkCopied(true);
        } catch (error) {
            console.error('Error copying link:', error);
        }
    };

    return (
        <div>
            <button onClick={handleCopyLink}

                style={{
                    padding: '2px 4px', // Adjust padding as needed
                    marginLeft: '1px', // Adjust margin left as needed
                    marginTop: '1px',
                    marginBottom: '1px',
                    backgroundColor: '#00adef', // Adjust background color
                    color: 'black', // Text color
                    border: 'none', // Remove default button border
                    borderRadius: '4px', // Rounded corners
                }}




            >
                Copy Link

            </button>
            {isLinkCopied && (
                <div style={{ color: 'red', fontStyle: 'italic', marginLeft: '1em' }}>
                    *** Link copied! ***
                </div>
            )}
        </div>
    );
}

export default CopyLinkButton;
