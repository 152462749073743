// timeUtils.js
export function formatTimeAgo(dateGmt) {
    const now = new Date();
    const timestamp = new Date(dateGmt);
    const diff = now - timestamp;

    if (diff < 60 * 1000) {
        // Less than a minute
        return `${Math.floor(diff / 1000)}s`;
    } else if (diff < 60 * 60 * 1000) {
        // Less than an hour
        return `${Math.floor(diff / (60 * 1000))}m`;
    } else if (diff < 24 * 60 * 60 * 1000) {
        // Less than a day
        return `${Math.floor(diff / (60 * 60 * 1000))}h`;
    } else if (diff < 30 * 24 * 60 * 60 * 1000) {
        // Less than a month
        return `${Math.floor(diff / (24 * 60 * 60 * 1000))}d`;
    } else if (diff < 365 * 24 * 60 * 60 * 1000) {
        // Less than a year
        return `${Math.floor(diff / (30 * 24 * 60 * 60 * 1000))}mths`;
    } else {
        // More than a year
        return `${Math.floor(diff / (365 * 24 * 60 * 60 * 1000))}yrs ago`;
    }
}

