import { useState } from 'react';

const useTags = (initialTags = [], maxTags = 5) => {
    const [tags, setTags] = useState(initialTags);

    const addTag = (tag) => {
        if (tag && !tags.includes(tag) && tags.length < maxTags) {
            setTags([...tags, tag]);
        }
    };

    const removeTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const getTags = () => tags;


    return {
        tags,
        addTag,
        removeTag,
        getTags,

    };
};

export default useTags;
