import React from 'react';
import Layout from '../components/Layout/Layout';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';


const About = () => {
    return (
        <Layout>

            <Helmet>
                <title>About</title>
                <meta
                    name="description"
                    content="About page for app.heshmore.com"
                />
                <meta name="keywords" content="heshmore app about us page" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/about" />
            </Helmet>

            <h1 style={{ padding: '20px' }}>About</h1>
            <section style={{ padding: '20px', marginLeft: '40px', marginRight: '40px' }}>
                <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    HeshmoreApp is leading news magazine that provides technology, science, mobility, innvoation and market research news from different publications around the world.

                </p>
                <p style={{ fontWeight: 'bold', fontSize: '15px' }}>

                    You can download HeshMoreApp on the Google Play Store or visit http://app.heshmore.com/

                </p>


            </section>

        </Layout>
    )
}

export default About
