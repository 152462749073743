import React, { useState } from 'react';
import DatePicker from 'react-datepicker'; // Import your preferred date picker library
import 'react-datepicker/dist/react-datepicker.css';


const UploadInputForm = ({ htmlFor, labeltext, type, name, value, handlechange, fieldName, hasError }) => {
    const inputStyle = hasError ? { border: '1px solid red' } : {};


    const [selectedDate, setSelectedDate] = useState(null); // Initialize with null

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handlechange(date); // Call parent's handlechange with the date object
    };
    return (
        <>
            {/* <div className="mb-3"> */}

            <div className="form-group">

                <label htmlFor={htmlFor} className="form-label">
                    {labeltext}
                </label>
                {type === 'date' ? (
                    // <DatePicker
                    //     selected={value}
                    //     // onChange={(date) => handlechange(date)} // Pass the date object
                    //     // onChange={handleDateChange} // Use the custom handleDateChange
                    //     onChange={handlechange} // Use the custom handleDateChange


                    //     name={fieldName} // Use fieldName for the name attribute
                    //     className="form-control" // Add optional class for styling
                    //     style={inputStyle}

                    // />

                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange} // Use the custom handleDateChange
                        name={fieldName}
                        className="form-control"
                        style={inputStyle}
                    />
                ) : (
                    <input
                        type={type}
                        className="form-control"
                        name={fieldName} // Use fieldName for the name attribute
                        value={value}
                        onChange={handlechange}
                        style={inputStyle}

                    />
                )}
            </div>
        </>
    );
};

export default UploadInputForm;
