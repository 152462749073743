import styled from 'styled-components';

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
 margin: 0 30px; /* Adjust margin values as needed */
  align-items: center;
margin-bottom: 20px;    
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ErrorSummary = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
`;

export { FormContainer, FormColumn, ErrorSummary };