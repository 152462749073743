import React from 'react'
import Layout from '../components/Layout/Layout';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import MailIcon from '@mui/icons-material/Mail';
import { Helmet } from 'react-helmet';


const Contact = () => {
    return (
        <Layout>


            <Helmet>
                <title>Contact Us</title>
                <meta
                    name="description"
                    content="Contact us page for app.heshmore.com"
                />
                <meta name="keywords" content="heshmore app contact us page" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://app.heshmore.com/contact" />
            </Helmet>
            <Box sx={{
                my: 5,
                p: 3,
                textAlign: 'left',
                "& h4": {

                    fontWeight: 'bold',
                },

                "& p": {

                    textAlign: 'justify',
                },

                "@media (max-width: 600px)": {

                    mt: 0,
                }

            }}  >
                <Typography variant='h4'>
                    Contact US:

                </Typography>
                <br></br>
                <p>
                    You can get in touch with us at the below mentioned email address.
                </p>
                <p>
                    If you want to post articles from your website, please contact the below email address with your details.
                    You can send email with your website URL and email id for which you need access to upload articles.
                </p>

            </Box>
            <Box sx={

                {

                    m: 4,
                    width: "600px",
                    ml: 10,
                    "@media (max-width:600px)": {
                        ml: 5,


                        width: "250px"
                    }
                }
            }>
                <TableContainer component={Paper}>
                    <Table aria-label='contact table'>
                        <TableHead>

                            <TableRow>
                                <TableCell sx={{ bgcolor: '#00adef', color: 'white' }} align='Center'>
                                    Contact Details

                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                    <MailIcon sx={{ color: '#00adef', pt: 0, fontSize: 40 }} />
                                    <span style={{ marginLeft: '8px' }}>contactus@heshmore.com</span>


                                </TableCell>
                            </TableRow>



                        </TableBody>


                    </Table>


                </TableContainer>


            </Box>
        </Layout>
    );

}

export default Contact
