import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { getCroppedImg } from '../../utils/cropImage.js'; // Utility function to get the cropped image
import '../../styles/ImageCropper.css'; // Import the CSS file

function ImageCropper({ imageSrc, onCropComplete }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [isCropping, setIsCropping] = useState(true);

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onZoomChange = (zoom) => {
        setZoom(zoom);
    };

    const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCrop = async () => {
        const containerWidth = 150; // Same as the container width in CSS
        const containerHeight = 150; // Same as the container height in CSS
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, containerWidth, containerHeight);
        setCroppedImage(croppedImage);
        setIsCropping(false);
        onCropComplete(croppedImage);
    };

    return (
        <div className="crop-container">
            {isCropping ? (
                <>
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={onCropChange}
                        onZoomChange={onZoomChange}
                        onCropComplete={onCropCompleteHandler}
                    />
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                    <Button variant="contained" color="primary" onClick={handleCrop}>
                        Crop
                    </Button>
                </>
            ) : (
                <img src={croppedImage} alt="Cropped" style={{ width: '100%', height: 'auto' }} />
            )}
        </div>
    );
}

export default ImageCropper;
