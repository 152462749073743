import React from 'react';
// import DatePicker from 'react-datepicker';

const Inputform = ({ htmlFor, labeltext, type, name, value, handlechange }) => {
    return (
        <>
            <div className="mb-3">
                <label htmlFor={htmlFor} className="form-label">
                    {labeltext}
                </label>



                <input type={type}
                    className="form-control"
                    name={name}
                    value={value}
                    onChange={handlechange}

                />

                {/* {type === 'date' ? (
                    <DatePicker
                        selected={value}
                        onChange={(date) => handlechange(date)} // Pass the date object
                        name={name}
                        className="form-control" // Add optional class for styling
                    />
                ) : (
                    <input
                        type={type}
                        className="form-control"
                        name={name}
                        value={value}
                        onChange={handlechange}
                    />
                )} */}
            </div>


        </>
    )
}

export default Inputform
